const axios = require("axios");
const config = require("../config");

class Role {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "roles", {
            params,
        });

        return {
            data: data.data.map((item) => new Role(item)),
            total: data.total,
        };
    }

    static getRoles() {
        return axios.get(config.api_url + "roles");
    }
}

export default Role;
