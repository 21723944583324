import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Calendar from "../../assets/components/teeTime/Calendar";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import NotificationActions from "../../store/actions/notification";
import { Button, Label, Select } from "semantic-ui-react";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import Member from "../../models/Member";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import GolfCourseSearchSelect from "../../assets/components/GolfCourseSearchSelect";
import GroupSearchSelect from "../../assets/components/GroupSearchSelect";
import EntitySearchSelect from "../../assets/components/EntitySearchSelect";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";

class TeeTimesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            bookings: [],
            calendarBookings: [],
            notification: null,
            error: false,
            calendarMonth: new Date().getMonth(),
            calendarYear: new Date().getFullYear(),
            filters: {
                club: [],
                entity: [],
                member: [],
                group: [],
                course: [],
                status: "active",
                bookingType: ["CREDIT", "CASH"],
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 100,
            },
            totalRecords: 0,
        };

        this.statusColours = {
            requested: "#007ab5",
            confirmed: "#00b571",
            booked: "#00b5ad",
            "awaiting confirmation": "#ffc53b",
            "cancellation requested": "#d76e6e",
            cancelled: "#7d2525",
        };

        this.statusFilterOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Active",
                value: "active",
            },
            {
                key: 2,
                text: "Booked",
                value: "booked",
            },
            {
                key: 3,
                text: "Cancelled",
                value: "cancelled",
            },
        ];
    }

    componentDidMount() {
        this.getBookings(this.state.table);
        this.getCalendarBookings();
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    showClaimedLabel(teeTime) {
        if (typeof teeTime.claimedBy === "undefined") {
            return "-";
        }

        return <Label>{teeTime.claimedBy.name}</Label>;
    }

    async getBookings(params) {
        this.setState({ loading: true });
        try {
            const { data, total } = await TeeTimeBooking.index(
                Object.assign(params, this.state.filters)
            );
            this.setState({
                table: params,
                loading: false,
                bookings: data,
                totalRecords: total,
            });
        } catch (_) {
            this.setState({ loading: false, error: true });
            this.notification(
                "error",
                "Failed to load Tee time bookings for calendar. Please try again."
            );
        }
    }

    async getCalendarBookings(
        month = this.state.calendarMonth,
        year = this.state.calendarYear
    ) {
        try {
            const calendarBookings = await TeeTimeBooking.getCalendarBookings({
                ...this.state.filters,
                month,
                year,
            });
            this.setState({ calendarBookings });
        } catch (_) {
            this.setState({ loading: false, error: true });
            this.notification(
                "error",
                "Failed to load Tee time bookings for calendar. Please try again."
            );
        }
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getBookings(table);
        this.getCalendarBookings();
    }

    viewButton(teeTime) {
        const showUnreadCount =
            this.props.user.isWatchingTeeTime(teeTime) ||
            this.props.user.hasClaimedTeeTime(teeTime);
        if (showUnreadCount && teeTime.unreadMessages > 0) {
            return (
                <Link to={`/tee-time-requests/${teeTime._id}`}>
                    <Button
                        primary
                        className="theme"
                        style={{ position: "relative" }}
                    >
                        <Label color="red" circular floating>
                            {teeTime.unreadMessages}
                        </Label>
                        View
                    </Button>
                </Link>
            );
        }

        return (
            <Link to={`/tee-time-requests/${teeTime._id}`}>
                <Button primary className="theme">
                    View
                </Button>
            </Link>
        );
    }

    styledStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status}
            </Label>
        );
    }

    requestedOrConfirmedDate(booking) {
        if (booking.hasConfirmedBooking === true) {
            return (
                new Date(booking.confirmedBooking.date).toLocaleDateString() +
                " (confirmed)"
            );
        }

        return new Date(booking.date).toLocaleDateString();
    }

    requestedOrConfirmedTime(booking) {
        if (booking.hasConfirmedBooking === true) {
            return booking.confirmedBooking.time + " (confirmed)";
        }

        return booking.timeslot;
    }

    requestedOrConfirmedClub(booking) {
        if (booking.hasConfirmedBooking === true) {
            return {
                name:
                    booking.confirmedBooking.course.club.name + " (confirmed)",
                _id: booking.confirmedBooking.course.club._id,
            };
        }

        return {
            name: booking.choices[0].course.club.name,
            _id: booking.choices[0].course.club._id,
        };
    }

    table(teeTimes) {
        const headers = [
            { key: "requested", text: "Date requested", searchable: true },
            { key: "memberName", text: "Member", searchable: true },
            { key: "entity", text: "Entity", searchable: true },
            { key: "club", text: "Club", searchable: true },
            { key: "date", text: "Booking date", searchable: true },
            { key: "timeslot", text: "Time slot", searchable: true },
            { key: "players", text: "Players", searchable: true },
            { key: "bookingType", text: "Booking type", searchable: true },
            { key: "status", text: "Status", searchable: true },
            { key: "payment", text: "Payment", searchable: true },
            { key: "claimed", text: "Claimed" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = teeTimes.map((teeTime) => {
            const club = this.requestedOrConfirmedClub(teeTime);
            const row = {
                requested: {
                    value: `${format(
                        new Date(teeTime.createdAt),
                        "dd/MM/yyyy kk:mm"
                    )} (${formatDistance(
                        new Date(),
                        new Date(teeTime.createdAt)
                    )} ago)`,
                },
                memberName: {
                    value: (
                        <>
                            <Link
                                className="mr-2"
                                to={`/members/${teeTime.user._id}`}
                            >
                                {teeTime.user.name}
                            </Link>
                            {
                                new Member(teeTime.user).getMembershipStatus()
                                    .label
                            }
                        </>
                    ),
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${teeTime.user.entity.entity._id}`}
                        >
                            {teeTime.user.entity.entity.name}
                        </Link>
                    ),
                },
                club: {
                    value: (
                        <Link to={`/golf-clubs/${club._id}`}>{club.name}</Link>
                    ),
                },
                date: {
                    value: this.requestedOrConfirmedDate(teeTime),
                },
                timeslot: { value: this.requestedOrConfirmedTime(teeTime) },
                players: { value: teeTime.players },
                bookingType: {
                    value: teeTime.isCreditBooking ? "Credit" : "Cash",
                },
                status: {
                    value: this.styledStatus(teeTime.status),
                },
                payment: {
                    value: teeTime.paymentStatus,
                },
                claimed: { value: this.showClaimedLabel(teeTime) },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                row.actions = { value: this.viewButton(teeTime) };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getBookings.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Status", key: "status" },
                    { text: "Payment status", key: "paymentStatus" },
                    { text: "Players", key: "players" },
                    { text: "Booking date", key: "date" },
                    { text: "Date requested", key: "createdAt" },
                    { text: "Member (grouped)", key: "user" },
                    { text: "Claimed by (grouped)", key: "claimedBy" },
                    { text: "Booking type", key: "isCreditBooking" },
                ]}
                pagination
            />
        );
    }

    list() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        return this.table(this.state.bookings);
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Tee time requests</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/tee-time-requests/create">
                            <Button primary className="theme">
                                Add Tee Time
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Select
                            placeholder="Filter by status"
                            search
                            value={this.state.filters.status}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("status", value)
                            }
                            options={this.statusFilterOptions}
                            className="mr-3"
                        />
                        <Select
                            placeholder="Filter by booking type"
                            search
                            multiple
                            value={this.state.filters.bookingType}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("bookingType", value)
                            }
                            options={[
                                { key: 0, text: "Credit", value: "CREDIT" },
                                { key: 1, text: "Cash", value: "CASH" },
                            ]}
                            className="mr-3"
                        />
                        <EntitySearchSelect
                            multiple
                            placeholder="Filter by entity"
                            value={this.state.filters.entity}
                            onChange={(value) =>
                                this.onChangeFilter("entity", value)
                            }
                            className="mr-3"
                        />
                        <GolfClubSearchSelect
                            multiple
                            value={this.state.filters.club}
                            onChange={(value) =>
                                this.onChangeFilter("club", value)
                            }
                            placeholder="Filter by club"
                            className="mr-3"
                        />
                        <GolfCourseSearchSelect
                            multiple
                            value={this.state.filters.course}
                            onChange={(value) =>
                                this.onChangeFilter("course", value)
                            }
                            club={this.state.filters.club}
                            placeholder="Filter by course"
                            className="mr-3"
                        />
                        <GroupSearchSelect
                            multiple
                            value={this.state.filters.group}
                            onChange={(value) =>
                                this.onChangeFilter("group", value)
                            }
                            placeholder="Filter by course group"
                            className="mr-3"
                        />
                        <MemberSearchSelect
                            onChange={(value) =>
                                this.onChangeFilter("member", value)
                            }
                            multiple
                            value={this.state.filters.member}
                            placeholder="Filter by member"
                        />
                    </div>
                </div>
                <hr />
                <Tabs defaultActiveKey="table">
                    <Tab eventKey="table" title="Table">
                        <div className="row mt-3">
                            <div className="col">{this.list()}</div>
                        </div>
                    </Tab>
                    <Tab eventKey="calendar" title="Calendar">
                        <div className="pt-3">
                            <Calendar
                                bookings={this.state.calendarBookings}
                                onChangeMonth={(month, year) => {
                                    this.getCalendarBookings(month, year);
                                    this.setState({
                                        calendarMonth: month,
                                        calendarYear: year,
                                    });
                                }}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(TeeTimesIndex);
