import React from "react";
import { connect } from "react-redux";

const ValidationError = ({ validation, field }) => {
    if (Array.isArray(validation?.[field]) === false) {
        return null;
    }

    return <span className="has-error"> {validation[field][0]} </span>;
};

export default connect((state) => ({ validation: state.validation }))(
    ValidationError
);
