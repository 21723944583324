import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";

import NotificationActions from "../../store/actions/notification";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import EventReserve from "../../models/EventReserve";
import moment from "moment";

class ReservesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
            filters: {
                member: null,
            },
            totalRecords: 0,
            reserves: [],
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    table() {
        const headers = [
            { key: "event", text: "Event" },
            { key: "venue", text: "Venue" },
            { key: "maxAttendees", text: "Max Attendees" },
            { key: "member", text: "Member" },
            { key: "players", text: "Players" },
            { key: "eventDate", text: "Event date" },
            { key: "reserveDate", text: "Reserve date" },
        ];

        const data = this.state.reserves.map((reserve) => {
            const event =
                typeof reserve.event !== "undefined" &&
                typeof reserve.event._id !== "undefined"
                    ? reserve.event._id
                    : false;
            const venue = reserve.event?.club ?? null;
            return {
                event: {
                    value: event ? (
                        <Link to={`/events/${reserve.event._id}`}>
                            {reserve.event.name}
                        </Link>
                    ) : (
                        "Event not found"
                    ),
                    search: event ? reserve.event.name : "Event not found",
                },
                venue: {
                    value: venue ? (
                        <Link to={`/golf-clubs/${reserve.event.club._id}`}>
                            {reserve.event.club.name}
                        </Link>
                    ) : (
                        "Venue not found"
                    ),
                    search: venue ? reserve.event.club.name : "Venue not found",
                },
                maxAttendees: { value: event ? reserve.event.maxUsers : "-" },
                member: {
                    value: (
                        <Link to={`/members/${reserve.user._id}`}>
                            {reserve.user.name}
                        </Link>
                    ),
                    search: reserve.user.name,
                },
                players: { value: reserve.players },
                eventDate: {
                    value: event
                        ? moment(reserve.event.date).format("DD/MM/YYYY")
                        : "",
                },
                reserveDate: {
                    value: moment(reserve.createdAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getReserves.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Event", key: "event" },
                    { text: "Players", key: "players" },
                    { text: "Member", key: "user" },
                ]}
                pagination
            />
        );
    }

    getReserves(params) {
        this.setState({ loading: true });
        EventReserve.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    loading: false,
                    reserves: data.reserves,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification("error", "Failed to load. Please try again");
            });
    }

    componentDidMount() {
        this.getReserves(this.state.table);
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getReserves(table);
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Event reserves</h2>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <h3>Filters</h3>
                        <MemberSearchSelect
                            onChange={(value) =>
                                this.onChangeFilter("member", value)
                            }
                            value={this.state.filters.member}
                            placeholder="Filter by member"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(ReservesIndex);
