import axios from "axios";
import profileImagePlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class MashieAdminUser {
    constructor(properties) {
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.roles = [];
        this.permissions = [];
        this.allPermissions = [];
        this.addresses = [];
        Object.assign(this, properties);
    }

    has(key) {
        if (typeof this.roles !== "undefined") {
            return this.allPermissions.some(
                (permission) => permission.key === key
            );
        }

        return false;
    }

    hasRole(key) {
        if (typeof this.roles !== "undefined") {
            return this.roles.some((role) => role.role.key === key);
        }

        return false;
    }

    static find() {
        return axios.get(config.api_url + "mashie-admins");
    }

    static findById(id) {
        return axios.get(config.api_url + "mashie-admins/" + id);
    }

    store() {
        return axios.post(config.api_url + "mashie-admins", {
            firstName: this.firstName,
            surname: this.surname,
            emailAddresses: this.emailAddresses.map((email) => ({
                email: email.email,
                emailType: email.emailType,
                primary: email.primary,
            })),
            roles: this.roles.map(({ role }) => role.key),
        });
    }

    updateProfileImage(profileImage) {
        return axios.put(
            config.api_url + "mashie-admins/" + this._id + "/profile-image",
            {
                profileImage,
            }
        );
    }

    update() {
        return axios.put(config.api_url + "mashie-admins/" + this._id, {
            firstName: this.firstName,
            surname: this.surname,
            emailAddresses: this.emailAddresses.map((email) => ({
                email: email.email,
                emailType: email.emailType,
                primary: email.primary,
            })),
        });
    }

    updateRoles() {
        return axios.put(
            config.api_url + "mashie-admins/" + this._id + "/roles",
            {
                roles: this.roles.map((role) => ({
                    role: role.role._id,
                    expiresAt: role.expiresAt,
                    activatesAt: role.activatesAt,
                    duration: role.duration,
                    cost: role.cost,
                    invoiceNumber: role.invoiceNumber,
                    paid: role.paid,
                    eventCredit: role.eventCredit,
                    teeTimeCredit: role.teeTimeCredit,
                    deletedAt: role.deletedAt,
                })),
            }
        );
    }

    delete() {
        return axios.delete(config.api_url + "mashie-admins/" + this._id);
    }

    get profileImageUrl() {
        if (typeof this.profileImage !== "undefined") {
            return config.content_url + "profiles/" + this.profileImage;
        }

        return profileImagePlaceholder;
    }

    static claimTransaction(userId, transactionId) {
        return axios
            .post(
                config.api_url +
                    "users/" +
                    userId +
                    "/transaction/" +
                    transactionId +
                    "/claim"
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default MashieAdminUser;
