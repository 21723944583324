import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import NotificationActions from "../../store/actions/notification";
import CourseGroup from "../../models/CourseGroup";
import DataTable from "../../assets/components/dataTable";
import { Link } from "react-router-dom";
import moment from "moment";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            error: null,
            notification: null,
            courseGroups: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getGroups(this.state.table);
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    getGroups(params) {
        this.setState({ loading: true });
        CourseGroup.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    table: params,
                    totalRecords: data.total,
                    courseGroups: data.groups,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.response.status,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    listCourses(courses) {
        return courses.map((course, index) => {
            return (
                <div key={index}>
                    <Link to={`/golf-clubs/${course.club._id}`}>
                        {course.name} at {course.club.name}
                    </Link>
                </div>
            );
        });
    }

    delete(group) {
        const confirm = window.confirm(
            "Are you sure you want to delete this group?"
        );
        if (confirm === true) {
            group
                .delete()
                .then((respose) => {
                    this.notification("success", "Group deleted successfully");
                    this.getGroups({ page: this.state.page });
                })
                .catch((error) => {
                    this.notification("error", "Failed to delete group");
                });
        }
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Created on" },
            { key: "name", text: "Group name" },
            { key: "courses", text: "Golf courses" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = this.state.courseGroups.map((group, i) => {
            const row = {
                createdAt: {
                    value: moment(group.createdAt).format("DD/MM/YYYY, HH:mm"),
                },
                name: { value: group.name },
                courses: {
                    value: this.listCourses(group.courses),
                },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                let actions = [];

                actions.push(
                    <Link to={`/course-groups/${group._id}`} key={"link-" + i}>
                        <Button primary className="theme" key={"view-" + i}>
                            View
                        </Button>
                    </Link>
                );

                if (group.courses.length === 0) {
                    actions.push(
                        <Button
                            primary
                            className="red"
                            onClick={() => this.delete(group)}
                            key={i}
                        >
                            Delete
                        </Button>
                    );
                }

                row.actions = { value: actions };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getGroups.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Name", key: "name" },
                ]}
                pagination
            />
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Course groups</h2>
                    </div>
                    <div className="col text-right">
                        <Link to="/course-groups/create">
                            <Button primary className="theme">
                                Create group
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

export default Show;
