import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Label, Dropdown } from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";

import NotificationActions from "../../store/actions/notification";
import CreditAllocation from "../../models/CreditAllocation";

class CreditAllocationsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            allocations: [],
            filters: {
                method: null,
                user: null,
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.methodOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Club reviews",
                value: "CLUB_REVIEW_REWARD",
            },
            {
                key: 2,
                text: "Event reviews",
                value: "EVENT_REVIEW_REWARD",
            },
        ];
    }

    componentDidMount() {
        this.getAllocations(this.state.table);
    }

    getAllocations(params) {
        this.setState({ loading: true });
        CreditAllocation.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    loading: false,
                    allocations: data.allocations,
                    table: params,
                    totalRecords: data.total,
                });
            })
            .catch(() => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load allocations, please try again"
                );
            });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getAllocations(table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Created" },
            { key: "user", text: "Member" },
            { key: "amount", text: "Amount" },
            { key: "method", text: "Method" },
        ];

        const data = this.state.allocations.map((allocation) => {
            return {
                createdAt: {
                    value: this.formatDate(allocation.createdAt),
                },
                user: {
                    value: this.getUserLink(allocation.user),
                },
                amount: {
                    value: `£${allocation.amount.toFixed(2)}`,
                },
                method: {
                    value:
                        allocation.method.charAt(0) +
                        allocation.method
                            .substring(1)
                            .toLowerCase()
                            .replaceAll("_", " "),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getAllocations.bind(this)}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Amount", key: "amount" },
                ]}
                pagination
                hideSearch
            />
        );
    }

    getUserLink(user) {
        const { _id, name } = user;
        return <Link to={`/members/${_id}`}>{name}</Link>;
    }

    getClubLink(club) {
        const { _id, name } = club;
        return <Link to={`/golf-clubs/${_id}`}>{name}</Link>;
    }

    getTransactionStatusLabel(status) {
        let labelColor = "blue";
        if (status === "COMPLETED") {
            labelColor = "green";
        }
        if (status === "PAYMENT_FAILED") {
            labelColor = "red";
        }

        return (
            <Label color={labelColor}>
                {status.charAt(0) +
                    status.substring(1).toLowerCase().replace("_", " ")}
            </Label>
        );
    }

    formatDate(date) {
        return moment(date).format("DD/MM/YYYY, HH:mm");
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Credit allocations</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Dropdown
                            selection
                            placeholder={"Method"}
                            options={this.methodOptions}
                            value={this.state.filters.method}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("method", value)
                            }
                            className={"ml-3"}
                        />
                        <MemberSearchSelect
                            placeholder="Filter by member"
                            value={this.state.filters.user}
                            onChange={(value) =>
                                this.onChangeFilter("user", value)
                            }
                            className="ml-3"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(CreditAllocationsIndex);
