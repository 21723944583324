import React, { Component } from "react";
import { Input, Segment, Select, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import countryList from "../../../utilities/countryList";
import CurrencySearchSelect from "../../../assets/components/CurrencySearchSelect";
import Currency from "../../../models/Currency";

class Details extends Component {
    constructor(props) {
        super(props);

        this.countries = countryList.map((country, index) => {
            return { key: index, value: country, text: country };
        });
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    gatherInput(property, value) {
        const { club } = this.props;
        club[property] = value;
        this.props.update(club);
    }

    gatherInputNumber(property, value) {
        const { club } = this.props;
        club[property] = parseInt(value) || 0;
        this.props.update(club);
    }

    addEmail() {
        const { club } = this.props;
        const primary = club.emailAddresses.length === 0;
        club.emailAddresses.push({ email: "", primary });
        this.props.update(club);
    }

    removeEmail(index) {
        const { club } = this.props;
        club.emailAddresses.splice(index, 1);
        this.props.update(club);
    }

    changeEmail(index, value) {
        const { club } = this.props;
        club.emailAddresses[index].email = value;
        this.props.update(club);
    }

    changeEmailPrimary(index, checked) {
        const { club } = this.props;
        club.emailAddresses = club.emailAddresses.map((email) => ({
            email: email.email,
            primary: false,
        }));
        club.emailAddresses[index].primary = checked;
        this.props.update(club);
    }

    addPhone() {
        const { club } = this.props;
        const primary = club.phoneNumbers.length === 0;
        club.phoneNumbers.push({ number: "", primary });
        this.props.update(club);
    }

    removePhone(index) {
        const { club } = this.props;
        club.phoneNumbers.splice(index, 1);
        this.props.update(club);
    }

    changePhone(index, value) {
        const { club } = this.props;
        club.phoneNumbers[index].number = value;
        this.props.update(club);
    }

    changePhonePrimary(index, checked) {
        const { club } = this.props;
        club.phoneNumbers = club.phoneNumbers.map((phone) => ({
            number: phone.number,
            primary: false,
        }));
        club.phoneNumbers[index].primary = checked;
        this.props.update(club);
    }

    async onChangeCurrency(id) {
        const currency = await Currency.getById(id);
        this.props.club.currency = currency;
        this.props.update(this.props.club);
    }

    listEmails() {
        return this.props.club.emailAddresses.map((email, i) => {
            return (
                <div className="row mt-3" key={i}>
                    <div className="col-sm-12 col-md-7 mb-2">
                        <Input
                            fluid
                            name={i}
                            placeholder="example@example.com"
                            value={email.email}
                            onChange={({ target }) =>
                                this.changeEmail(i, target.value)
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-5 text-right">
                        <Checkbox
                            toggle
                            label="Primary"
                            value={i}
                            checked={email.primary}
                            onChange={(_, value) =>
                                this.changeEmailPrimary(i, value.checked)
                            }
                        />
                        <Button
                            primary
                            className="red ml-3"
                            value={i}
                            onClick={() => this.removeEmail(i)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    listPhoneNumbers() {
        return this.props.club.phoneNumbers.map((phone, i) => {
            return (
                <div className="row mt-3" key={i}>
                    <div className="col-sm-12 col-md-7 mb-2">
                        <Input
                            fluid
                            name={i}
                            placeholder="0 123 456 789"
                            value={phone.number}
                            onChange={({ target }) =>
                                this.changePhone(i, target.value)
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-5 text-right">
                        <Checkbox
                            toggle
                            label="Primary"
                            value={i}
                            checked={phone.primary}
                            onChange={(_, value) =>
                                this.changePhonePrimary(i, value.checked)
                            }
                        />
                        <Button
                            primary
                            className="red ml-3"
                            value={i}
                            onClick={() => this.removePhone(i)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.props.club.name || ""}
                        onChange={({ target }) =>
                            this.gatherInput("name", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("emailAddresses")}
                    {this.errorsFor("email")}
                    <label className="with-input">Emails</label>
                    {this.listEmails()}
                    <div className="row">
                        <div className="col-12">
                            <Button
                                primary
                                className="theme mt-3"
                                onClick={this.addEmail.bind(this)}
                            >
                                Add email
                            </Button>
                        </div>
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("phoneNumbers")}
                    {this.errorsFor("phone")}
                    <label className="with-input">Telephone numbers</label>
                    {this.listPhoneNumbers()}
                    <div className="row">
                        <div className="col-12">
                            <Button
                                primary
                                className="theme mt-3"
                                onClick={this.addPhone.bind(this)}
                            >
                                Add Phone number
                            </Button>
                        </div>
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address1")}
                    <label className="with-input">Address line 1</label>
                    <Input
                        fluid
                        name="address1"
                        value={this.props.club.address1 || ""}
                        onChange={({ target }) =>
                            this.gatherInput("address1", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address2")}
                    <label className="with-input">Address line 2</label>
                    <Input
                        fluid
                        name="address2"
                        value={this.props.club.address2 || ""}
                        onChange={({ target }) =>
                            this.gatherInput("address2", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("town")}
                    <label className="with-input">Town</label>
                    <Input
                        fluid
                        name="town"
                        value={this.props.club.town || ""}
                        onChange={({ target }) =>
                            this.gatherInput("town", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("county")}
                    <label className="with-input">Region/County</label>
                    <Input
                        fluid
                        name="county"
                        value={this.props.club.county || ""}
                        onChange={({ target }) =>
                            this.gatherInput("county", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("postcode")}
                    <label className="with-input">Postcode</label>
                    <Input
                        fluid
                        name="postcode"
                        value={this.props.club.postcode || ""}
                        onChange={({ target }) =>
                            this.gatherInput("postcode", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("country")}
                    <label className="with-input">Country</label>
                    <Select
                        fluid
                        search
                        options={this.countries}
                        value={this.props.club.country || ""}
                        onChange={(_, result) =>
                            this.gatherInput("country", result.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("currency")}
                    <label className="with-input">Currency</label>
                    <CurrencySearchSelect
                        fluid
                        placeholder="Select a currency"
                        value={this.props.club.currency?._id ?? ""}
                        onChange={(value) => {
                            this.onChangeCurrency(value);
                        }}
                        className="mr-3"
                    />
                </Segment>
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.props.save}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Details);
