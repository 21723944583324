const axios = require("axios");
const config = require("../config");

class Currency {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async getById(id) {
        const { data } = await axios.get(config.api_url + "currencies/" + id);
        return new Currency(data.data);
    }

    static getCurrencies() {
        return axios.get(config.api_url + "currencies");
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "currencies/list", {
            params,
        });
        return data.data.map((currency) => new Currency(currency));
    }

    store() {
        return axios.post(config.api_url + "currencies", {
            name: this.name,
            symbol: this.symbol,
            icon: this.icon,
        });
    }

    update() {
        return axios.put(config.api_url + "currencies/" + this._id, {
            name: this.name,
            symbol: this.symbol,
            icon: this.icon,
        });
    }
}

export default Currency;
