import React, { Component } from "react";
import {
    Input,
    Segment,
    Form,
    Checkbox,
    TextArea,
    Modal,
    Label,
    Button,
    Icon,
} from "semantic-ui-react";
import Piece from "../../models/Piece";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./create.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Entity from "../../models/Entity";

import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Role from "../../models/Role";
import User from "../../models/User";
import CenterLoader from "../../assets/components/CenterLoader";
import ImageCropper from "../../assets/components/ImageCropper";
const config = require("../../config");

class Create extends Component {
    constructor(props) {
        super(props);
        const entity = new Entity();
        entity.emailAddresses = [];
        this.state = {
            saving: false,
            error: null,
            entity: entity,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    gatherInput(event) {
        const entity = this.state.entity;
        entity[event.target.name] = event.target.value;
        this.setState({ entity: entity });
    }

    addEmail() {
        const { entity } = this.state;
        entity.emailAddresses.push({ email: "", primary: false });
        this.setState({ entity });
    }

    removeEmail(event) {
        const { entity } = this.state;
        entity.emailAddresses.splice(event.target.value, 1);
        this.setState({ entity });
    }

    changeEmail(event) {
        const { entity } = this.state;
        entity.emailAddresses[event.target.name].email = event.target.value;
        this.setState({ entity });
    }

    changeEmailPrimary(event) {
        const { entity } = this.state;
        const checked = !event.target.previousSibling.checked;
        entity.emailAddresses[event.target.previousSibling.value].primary =
            checked;
        this.setState({ entity });
    }

    onChangeBranding(event, key) {
        const entity = this.state.entity;
        const { value } = event.target;
        entity.branding[key] = value;
        this.setState({ entity: entity });
    }

    store() {
        this.setState({ saving: true });
        this.state.entity
            .store()
            .then((response) => {
                this.notification("success", "Entity saved");
                this.props.history.push("/entities");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save Entity");
                this.handleValidationErrors(error);
            });
    }

    entityDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the entity's details.</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">
                        Name <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.entity.name}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("emailAddresses")}
                    {this.errorsFor("email")}
                    <label className="with-input">
                        Emails <b>*</b>
                    </label>
                    {this.state.entity.emailAddresses.map((email, i) => {
                        return (
                            <div className="row mt-3" key={i}>
                                <div className="col-sm-12 col-md-7 mb-2">
                                    <Input
                                        fluid
                                        name={i}
                                        placeholder="example@example.com"
                                        value={email.email}
                                        onChange={this.changeEmail.bind(this)}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-5 text-right">
                                    <Checkbox
                                        toggle
                                        label="Primary"
                                        value={i}
                                        checked={email.primary}
                                        onChange={this.changeEmailPrimary.bind(
                                            this
                                        )}
                                    />
                                    <Button
                                        primary
                                        className="red ml-3"
                                        value={i}
                                        onClick={this.removeEmail.bind(this)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="row">
                        <div className="col-12">
                            <Button
                                primary
                                className="theme mt-3"
                                onClick={this.addEmail.bind(this)}
                            >
                                Add email
                            </Button>
                        </div>
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address1")}
                    <label className="with-input">
                        Address line 1 <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="address1"
                        value={this.state.entity.address1}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address2")}
                    <label className="with-input">Address line 2</label>
                    <Input
                        fluid
                        name="address2"
                        value={this.state.entity.address2}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("town")}
                    <label className="with-input">
                        Town <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="town"
                        value={this.state.entity.town}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("county")}
                    <label className="with-input">
                        County <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="county"
                        value={this.state.entity.county}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("postcode")}
                    <label className="with-input">
                        Postcode <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="postcode"
                        value={this.state.entity.postcode}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("country")}
                    <label className="with-input">
                        Country <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="country"
                        value={this.state.entity.country}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("brandingPrimary")}
                    <label className="with-input">
                        Primary branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.primary || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "primary")
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("brandingSecondary")}
                    <label className="with-input">
                        Secondary branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.secondary || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "secondary")
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("brandingTertiary")}
                    <label className="with-input">
                        Tertiary branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.tertiary || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "tertiary")
                        }
                    />
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container skinny">
                <div className="row skinny">
                    <div className="col">
                        <h2>Create entity</h2>
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.entityDetailsTab()}
                            </Tab>
                        </Tabs>
                        <Button
                            size="large"
                            floated="right"
                            className="theme"
                            primary
                            onClick={this.store.bind(this)}
                            disabled={this.state.saving}
                            loading={this.state.saving}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
