import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";

import NotificationActions from "../../store/actions/notification";
import { Button, Label } from "semantic-ui-react";
import AppVariable from "../../models/AppVariable";

class AppVariablesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            appVariables: [],
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    getAppVariables() {
        this.setState({ loading: true });
        AppVariable.getAppVariables()
            .then((appVariable) => {
                this.setState({
                    loading: false,
                    appVariables: appVariable.data.data.map(
                        (appVariable) => new AppVariable(appVariable)
                    ),
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load app variables, please try again"
                );
            });
    }

    componentDidMount() {
        this.getAppVariables();
    }

    table() {
        const headers = [
            { key: "name", text: "Name", searchable: true },
            { key: "isEnabled", text: "Enabled/Disabled" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.appVariables.map((appVariable) => {
            return {
                name: { value: appVariable.name, search: appVariable.name },
                isEnabled: {
                    value: appVariable.isEnabled ? (
                        <Label color="green">Enabled</Label>
                    ) : (
                        <Label color="red">Disabled</Label>
                    ),
                },
                actions: {
                    value: (
                        <>
                            <Link to={`/app-variables/${appVariable._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
            />
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-10">
                        <h2>App Variables</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/app-variables/create">
                            <Button primary className="theme">
                                Add app variable
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(AppVariablesIndex);
