import { combineReducers } from "redux";
import user from "./user";
import roles from "./roles";
import login from "./login";
import register from "./register";
import notification from "./notification";
import validation from "./validation";
import subjectUser from "./subjectUser";
import subjectGolfClub from "./subjectGolfClub";

export default combineReducers({
    user,
    roles,
    login,
    register,
    notification,
    validation,
    subjectUser,
    subjectGolfClub,
});
