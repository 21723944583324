import { createStore } from "redux";
import rootReducer from "./reducers";
import User from "../models/User";
import GolfClub from "../models/GolfClub";

const store = createStore(rootReducer, {
    user: new User(),
    login: { errors: [] },
    register: { errors: [] },
    roles: [],
    notification: null,
    validation: null,
    subjectUser: new User(),
    subjectGolfClub: new GolfClub(),
});

export default store;
