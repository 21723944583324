import React, { useState } from "react";
import { Button, Segment, Form, TextArea } from "semantic-ui-react";
import { formatDistanceToNow } from "date-fns";

export default ({ notes, placeholder, onSave }) => {
    const [newNote, setNewNote] = useState("");

    return (
        <>
            <Form className="mb-3 mt-3">
                <TextArea
                    value={newNote}
                    placeholder={placeholder}
                    onChange={({ target }) => setNewNote(target.value)}
                />
                <Button
                    primary
                    className="theme mt-3"
                    onClick={() => {
                        onSave(newNote);
                        setNewNote("");
                    }}
                    disabled={newNote.length === 0}
                >
                    Add
                </Button>
            </Form>
            {notes.map((note, index) => {
                return (
                    <Segment vertical key={index}>
                        <span>
                            <b>{note.createdBy.name}</b>
                        </span>
                        <span className="ml-3">
                            {`${formatDistanceToNow(
                                new Date(note.createdAt)
                            )} ago`}
                        </span>
                        <p className="mt-3">{note.note}</p>
                        {note.customerReadAt && (
                            <p className="mt-3">
                                <small>
                                    {`Read by customer ${formatDistanceToNow(
                                        new Date(note.customerReadAt)
                                    )} ago`}
                                </small>
                            </p>
                        )}
                    </Segment>
                );
            })}
        </>
    );
};
