import React, { Component } from "react";
import {
    Input,
    Segment,
    Checkbox,
    Label,
    Button,
    Select,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Notes from "../../assets/components/entity/Notes";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Entity from "../../models/Entity";

import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import User from "../../models/User";
import CenterLoader from "../../assets/components/CenterLoader";
import ImageCropper from "../../assets/components/ImageCropper";

class Show extends Component {
    constructor(props) {
        super(props);
        const newAdmin = new User();
        newAdmin.emailAddresses = [];
        this.state = {
            saving: false,
            loadingEntity: true,
            loadingUsers: true,
            error: null,
            notification: null,
            validationErrors: {},
            validationErrorsNewAdmin: {},
            entity: new Entity(),
            newAdmin: newAdmin,
            users: [],
            canSendInternalMessage: true,
        };

        this.entityTypeDropdownOptions = [
            { key: 0, text: "Corporate", value: "Corporate" },
            { key: 1, text: "Society", value: "Society" },
            { key: 2, text: "Golf Club", value: "Golf Club" },
            { key: 3, text: "Partner Brand", value: "Partner Brand" },
            { key: 4, text: "Golf Brand", value: "Golf Brand" },
            { key: 5, text: "Other", value: "Other" },
        ];

        this.entityStatusDropdownOptions = [
            { key: 0, text: "Lead", value: "Lead" },
            { key: 1, text: "Member", value: "Member" },
        ];

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getEntity();
    }

    update(entity) {
        this.setState({ entity });
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getEntity() {
        const { id } = this.props.match.params;
        Entity.show(id)
            .then((entity) => {
                this.setState({
                    loadingEntity: false,
                    entity,
                });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification(
                    "error",
                    "Failed to entity, please try again"
                );
            });
    }

    onLogoChange(base64ImageString) {
        const { entity } = this.state;
        entity.updateLogo(base64ImageString).then(({ logo }) => {
            entity.logo = logo;
            this.setState({ entity });
        });
    }

    errorsFor(field) {
        if (typeof this.state.validationErrors[field] !== "undefined") {
            return (
                <span className="has-error">
                    {" "}
                    {this.state.validationErrors[field][0]}{" "}
                </span>
            );
        }
    }

    gatherInput(event) {
        const entity = this.state.entity;
        entity[event.target.name] = event.target.value;
        this.setState({ entity: entity });
    }

    addEmail() {
        const { entity } = this.state;
        entity.emailAddresses.push({ email: "", primary: false });
        this.setState({ entity });
    }

    removeEmail(event) {
        const { entity } = this.state;
        entity.emailAddresses.splice(event.target.value, 1);
        this.setState({ entity });
    }

    changeEmail(event) {
        const { entity } = this.state;
        entity.emailAddresses[event.target.name].email = event.target.value;
        this.setState({ entity });
    }

    changeEmailPrimary(event) {
        const { entity } = this.state;
        const checked = !event.target.previousSibling.checked;
        entity.emailAddresses[event.target.previousSibling.value].primary =
            checked;
        this.setState({ entity });
    }

    save() {
        this.setState({ saving: true });
        this.state.entity
            .update()
            .then(() => {
                this.setState({
                    saving: false,
                });
                this.notification("success", "Entity saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save Entity");
                this.handleValidationErrors(error);
            });
    }

    onChangeBranding(event, key) {
        const entity = this.state.entity;
        const { value } = event.target;
        entity.branding[key] = value;
        this.setState({ entity });
    }

    addPhone() {
        const entity = this.state.entity;
        const primary = entity.phoneNumbers.length === 0;
        entity.phoneNumbers.push({ number: "", primary: primary });
        this.setState({ entity });
    }

    removePhone(event) {
        const entity = this.state.entity;
        entity.phoneNumbers.splice(event.target.value, 1);
        this.setState({ entity });
    }

    changePhone(event) {
        const entity = this.state.entity;
        entity.phoneNumbers[event.target.name].number = event.target.value;
        this.setState({ entity });
    }

    changePhonePrimary(event) {
        const entity = this.state.entity;
        const checked = !event.target.previousSibling.checked;
        entity.phoneNumbers = entity.phoneNumbers.map((phone) => ({
            number: phone.number,
            primary: false,
        }));
        entity.phoneNumbers[event.target.previousSibling.value].primary =
            checked;
        this.setState({ entity });
    }

    onChangeEntityType(event, selection) {
        const { entity } = this.state;
        entity.entityType = selection.value;
        this.setState({ entity });
    }

    onChangeEntityStatus(event, selection) {
        const { entity } = this.state;
        entity.status = selection.value;
        this.setState({ entity });
    }

    claimEntity() {
        const confirm = window.confirm(
            "Are you sure you want to claim this entity?"
        );
        if (confirm === true) {
            const { entity } = this.state;
            entity
                .claim()
                .then((response) => {
                    entity.claimedBy = this.props.user;
                    this.setState({ entity });
                    this.notification("success", "Entity claimed");
                })
                .catch((error) => {
                    this.notification("error", "Failed to claim Entity");
                });
        }
    }

    unclaimEntity() {
        const confirm = window.confirm(
            "Are you sure you want to unclaim this entity?"
        );
        if (confirm === true) {
            const { entity } = this.state;
            entity
                .unclaim()
                .then((response) => {
                    delete entity.claimedBy;
                    this.setState({ entity });
                    this.notification("success", "Entity unclaimed");
                })
                .catch((error) => {
                    this.notification("error", "Failed to unclaim Entity");
                });
        }
    }

    showClaimLabel() {
        if (typeof this.state.entity.claimedBy !== "undefined") {
            return (
                <>
                    <div className="row">
                        <Label as="a" image>
                            <img
                                src={User.profileImageUrlForUser(
                                    this.state.entity.claimedBy
                                )}
                            />
                            Claimed by {this.state.entity.claimedBy.name}
                        </Label>
                    </div>
                    {this.state.entity.claimedBy._id ===
                        this.props.user._id && (
                        <div className="row mt-4">
                            <Button
                                primary
                                className="theme"
                                onClick={this.unclaimEntity.bind(this)}
                            >
                                Unclaim
                            </Button>
                        </div>
                    )}
                </>
            );
        }
    }

    showClaimButton() {
        const { entity } = this.state;
        if (this.props.user.hasClaimedEntity(entity) === false) {
            return (
                <Button
                    primary
                    className="theme"
                    onClick={this.claimEntity.bind(this)}
                >
                    Claim
                </Button>
            );
        }
    }

    loaded() {
        return this.state.loadingEntity === false;
    }

    listPhoneNumbers() {
        return this.state.entity.phoneNumbers.map((phone, i) => {
            return (
                <div className="row mt-3" key={i}>
                    <div className="col-sm-12 col-md-7 mb-2">
                        <Input
                            fluid
                            name={i}
                            placeholder="0 123 456 789"
                            value={phone.number}
                            onChange={this.changePhone.bind(this)}
                        />
                    </div>
                    <div className="col-xs-12 col-md-5 text-right">
                        <Checkbox
                            toggle
                            label="Primary"
                            value={i}
                            checked={phone.primary}
                            onChange={this.changePhonePrimary.bind(this)}
                        />
                        <Button
                            primary
                            className="red ml-3"
                            value={i}
                            onClick={this.removePhone.bind(this)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    delete() {
        const confirm = window.confirm(
            "Are you sure you want to delete this entity?"
        );
        if (confirm === true) {
            this.state.entity
                .delete()
                .then((response) => {
                    this.notification("succes", "Entity deleted");
                    this.props.history.push("/entities");
                })
                .catch((error) => {
                    this.notification("error", "Failed to delete club");
                });
        }
    }

    internalNotesTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Internal notes</h3>
                <Notes
                    entity={this.state.entity}
                    update={this.update.bind(this)}
                />
            </div>
        );
    }

    manageTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Manage</h3>
                <p>Deleted clubs are recoverable at a later date</p>
                <Button
                    primary
                    onClick={this.delete.bind(this)}
                    className="red"
                >
                    Delete club
                </Button>
            </div>
        );
    }

    entityBasic() {
        return (
            <div className={styles.topSegment + " skinny"}>
                <div className="row skinny">
                    <div className="col text-center">
                        <img
                            className="profile-logo-lg"
                            src={this.state.entity.logoUrl}
                        />
                        <ImageCropper onChange={this.onLogoChange.bind(this)} />
                        <h3>{this.state.entity.name}</h3>
                    </div>
                </div>
                <div className="row skinny">
                    <div className="col">
                        <label className="with-input">
                            Entity Country member signup link
                        </label>
                        <Input
                            fluid
                            value={this.state.entity.registrationLink}
                        />
                    </div>
                </div>
            </div>
        );
    }

    entityDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the entity's details.</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.entity.name}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("emailAddresses")}
                    {this.errorsFor("email")}
                    <label className="with-input">Emails</label>
                    {this.state.entity.emailAddresses.map((email, i) => {
                        return (
                            <div className="row mt-3" key={i}>
                                <div className="col-sm-12 col-md-7 mb-2">
                                    <Input
                                        fluid
                                        name={i}
                                        placeholder="example@example.com"
                                        value={email.email}
                                        onChange={this.changeEmail.bind(this)}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-5 text-right">
                                    <Checkbox
                                        toggle
                                        label="Primary"
                                        value={i}
                                        checked={email.primary}
                                        onChange={this.changeEmailPrimary.bind(
                                            this
                                        )}
                                    />
                                    <Button
                                        primary
                                        className="red ml-3"
                                        value={i}
                                        onClick={this.removeEmail.bind(this)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="row">
                        <div className="col-12">
                            <Button
                                primary
                                className="theme mt-3"
                                onClick={this.addEmail.bind(this)}
                            >
                                Add email
                            </Button>
                        </div>
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("phoneNumbers")}
                    {this.errorsFor("phone")}
                    <label className="with-input">Telephone numbers</label>
                    {this.listPhoneNumbers()}
                    <div className="row">
                        <div className="col-12">
                            <Button
                                primary
                                className="theme mt-3"
                                onClick={this.addPhone.bind(this)}
                            >
                                Add Phone number
                            </Button>
                        </div>
                    </div>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address1")}
                    <label className="with-input">Address line 1</label>
                    <Input
                        fluid
                        name="address1"
                        value={this.state.entity.address1}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("address2")}
                    <label className="with-input">Address line 2</label>
                    <Input
                        fluid
                        name="address2"
                        value={this.state.entity.address2}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("town")}
                    <label className="with-input">Town</label>
                    <Input
                        fluid
                        name="town"
                        value={this.state.entity.town}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("county")}
                    <label className="with-input">County</label>
                    <Input
                        fluid
                        name="county"
                        value={this.state.entity.county}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("postcode")}
                    <label className="with-input">Postcode</label>
                    <Input
                        fluid
                        name="postcode"
                        value={this.state.entity.postcode}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("country")}
                    <label className="with-input">Country</label>
                    <Input
                        fluid
                        name="country"
                        value={this.state.entity.country}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Entity type</label>
                    <Select
                        search
                        value={this.state.entity.entityType}
                        onChange={this.onChangeEntityType.bind(this)}
                        options={this.entityTypeDropdownOptions}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Entity status</label>
                    <Select
                        search
                        value={this.state.entity.status}
                        onChange={this.onChangeEntityStatus.bind(this)}
                        options={this.entityStatusDropdownOptions}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("primaryContactName")}
                    <label className="with-input">Primary contact name</label>
                    <Input
                        fluid
                        name="primaryContactName"
                        value={this.state.entity.primaryContactName}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <h3>Branding</h3>
                {this.errorsFor("branding")}
                <Segment vertical>
                    <label className="with-input">
                        Primary branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.primary || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "primary")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Secondary branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.secondary || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "secondary")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Neutral branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.neutral || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "neutral")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">White branding colour</label>
                    <Input
                        fluid
                        value={this.state.entity.branding.white || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "white")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Positive branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.positive || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "positive")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Negative branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.negative || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "negative")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Warning branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.warning || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "warning")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Disabled branding colour
                    </label>
                    <Input
                        fluid
                        value={this.state.entity.branding.disabled || ""}
                        onChange={(event) =>
                            this.onChangeBranding(event, "disabled")
                        }
                    />
                </Segment>
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                {this.showClaimLabel()}
                <div className="row skinny mb-3">{this.showClaimButton()}</div>
                {this.entityBasic()}
                <div className="row skinny">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.entityDetailsTab()}
                            </Tab>
                            <Tab
                                eventKey="internalNotes"
                                title="Internal notes"
                            >
                                {this.internalNotesTab()}
                            </Tab>
                            <Tab eventKey="manage" title="Manage">
                                {this.manageTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
