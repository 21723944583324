import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Dropdown, Button } from "semantic-ui-react";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import DataTable from "../../../assets/components/dataTable";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import GolfClubSearchSelect from "../../../assets/components/GolfClubSearchSelect";
import StarSelectDisplaySimple from "../../../assets/components/StarSelectDisplaySimple";
import NotificationActions from "../../../store/actions/notification";
import ClubReview from "../../../models/ClubReview";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reviews: [],
            filters: {
                clubStars: null,
                status: "SUBMITTED",
                user: null,
                club: null,
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.statusOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Awaiting completion",
                value: "PENDING",
            },
            {
                key: 2,
                text: "Awaiting approval",
                value: "SUBMITTED",
            },
            {
                key: 3,
                text: "Approved",
                value: "APPROVED",
            },
            {
                key: 4,
                text: "Rejected",
                value: "REJECTED",
            },
            {
                key: 5,
                text: "Expired",
                value: "EXPIRED",
            },
        ];

        this.starsOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "★☆☆☆☆",
                value: 1,
            },
            {
                key: 2,
                text: "★★☆☆☆",
                value: 2,
            },
            {
                key: 3,
                text: "★★★☆☆",
                value: 3,
            },
            {
                key: 4,
                text: "★★★★☆",
                value: 4,
            },
            {
                key: 5,
                text: "★★★★★",
                value: 5,
            },
        ];
    }

    componentDidMount() {
        this.getTransactions(this.state.table);
    }

    getTransactions(params) {
        this.setState({ loading: true });
        ClubReview.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    loading: false,
                    reviews: data.reviews,
                    table: params,
                    totalRecords: data.total,
                });
            })
            .catch(() => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load reviews, please try again"
                );
            });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getTransactions(table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Sent to member" },
            { key: "submittedAt", text: "Completed by member" },
            { key: "approvedAt", text: "Approved by Mashie" },
            { key: "status", text: "Status" },
            { key: "user", text: "Member" },
            { key: "club", text: "Club" },
            { key: "reviewRating", text: "MASHIE Member Rating" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.reviews.map((review) => {
            return {
                createdAt: {
                    value: this.formatDate(review.createdAt),
                },
                submittedAt: {
                    value: review.submittedAt
                        ? this.formatDate(review.submittedAt)
                        : "-",
                },
                approvedAt: {
                    value: review.approvedAt
                        ? this.formatDate(review.approvedAt)
                        : "-",
                },
                status: {
                    value: this.formatStatus(review.status),
                },
                user: {
                    value: this.getUserLink(review.user),
                },
                club: {
                    value: this.getClubLink(review),
                },
                reviewRating: {
                    value: review.rating ? (
                        <StarSelectDisplaySimple score={review.rating} />
                    ) : (
                        "-"
                    ),
                },
                actions: {
                    value:
                        review.status === "PENDING" ? (
                            "-"
                        ) : (
                            <Link to={`/reviews/clubs/${review._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                        ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getTransactions.bind(this)}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Sent to member", key: "createdAt" },
                    { text: "Completed by member", key: "submittedAt" },
                    { text: "Approved by Mashie", key: "approvedAt" },
                    { text: "Rejected by Mashie", key: "rejectedAt" },
                    { text: "Expired", key: "expiredAt" },
                    { text: "Club rating", key: "clubStars" },
                    { text: "Course rating", key: "courseStars" },
                    {
                        text: "Booking process rating",
                        key: "bookingProcessStars",
                    },
                    { text: "Credit value", key: "creditValue" },
                ]}
                pagination
                hideSearch
            />
        );
    }

    getUserLink(user) {
        const { _id, name } = user;
        return <Link to={`/members/${_id}`}>{name}</Link>;
    }

    getClubLink(review) {
        return (
            <>
                <Link to={`/golf-clubs/${review.club._id}`}>
                    {review.club.name}
                </Link>
            </>
        );
    }

    formatDate(date) {
        return moment(date).format("DD/MM/YYYY, HH:mm");
    }

    formatStatus(status) {
        const options = {
            PENDING: "Awaiting completion",
            SUBMITTED: "Awaiting approval",
            APPROVED: "Approved",
            REJECTED: "Rejected",
            EXPIRED: "Expired",
        };
        return options[status];
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Club reviews</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Dropdown
                            selection
                            placeholder={"Club rating"}
                            options={this.starsOptions}
                            value={this.state.filters.clubStars}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("clubStars", value)
                            }
                            className={"ml-3"}
                        />
                        <Dropdown
                            selection
                            placeholder={"Status"}
                            options={this.statusOptions}
                            value={this.state.filters.status}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("status", value)
                            }
                            className={"ml-3"}
                        />
                        <MemberSearchSelect
                            placeholder="Filter by member"
                            value={this.state.filters.user}
                            onChange={(value) =>
                                this.onChangeFilter("user", value)
                            }
                            className="ml-3"
                        />
                        <GolfClubSearchSelect
                            placeholder="Filter by club"
                            value={this.state.filters.club}
                            onChange={(value) =>
                                this.onChangeFilter("club", value)
                            }
                            className="ml-3"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Index);
