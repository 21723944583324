import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import NotificationActions from "../../../store/actions/notification";

class Management extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
        };
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    delete() {
        const confirm = window.confirm(
            "Are you sure you want to delete this club?"
        );
        if (confirm === true) {
            this.setState({ deleting: true });
            this.props.club
                .delete()
                .then(() => {
                    this.setState({ deleting: false });
                    this.notification("succes", "Club deleted");
                    window.location = "/golf-clubs";
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ deleting: false });
                    this.notification("error", "Failed to delete club");
                });
        }
    }

    deleteButton() {
        return (
            <>
                <p>Deleted clubs are recoverable at a later date</p>
                <Button
                    primary
                    loading={this.state.deleting}
                    onClick={this.delete.bind(this)}
                    className="red"
                >
                    Delete club
                </Button>
            </>
        );
    }

    render() {
        return <>{this.deleteButton()}</>;
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Management);
