import axios from "axios";
const config = require("../config");

class GolfCourse {
    constructor(properties) {
        this.name = "";
        this.par = 0;
        this.holes = 0;
        this.yardage = 0;
        this.description = "";
        this.creditPricing = [];
        this.acceptsCredit = false;
        this.greenFees = {
            summer: {
                highest: null,
                monday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                tuesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },

                    club: { am: null, pm: null },
                },
                wednesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },

                    club: { am: null, pm: null },
                },
                thursday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                friday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                saturday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                sunday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
            },
            winter: {
                highest: null,
                monday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                tuesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                wednesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                thursday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                friday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                saturday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                sunday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
            },
        };

        Object.assign(this, properties);
    }

    static getById(id) {
        return axios.get(config.api_url + "clubs/" + id);
    }

    store(clubId) {
        return axios.post(config.api_url + "clubs/" + clubId + "/courses", {
            name: this.name,
            holes: this.holes,
            par: this.par,
            yardage: this.yardage,
            description: this.description,
            greenFees: this.greenFees,
            greenFees: this.greenFees,
            acceptsCredit: this.acceptsCredit,
            creditPricing: this.creditPricing,
            group: this.group,
        });
    }

    update() {
        return axios.put(
            config.api_url + "clubs/" + this.club._id + "/courses/" + this._id,
            {
                name: this.name,
                holes: this.holes,
                par: this.par,
                yardage: this.yardage,
                description: this.description,
                greenFees: this.greenFees,
                acceptsCredit: this.acceptsCredit,
                creditPricing: this.creditPricing,
                group: this.group,
            }
        );
    }

    delete() {
        return axios.delete(
            config.api_url + "clubs/" + this.club._id + "/courses/" + this._id
        );
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "courses/list", {
            params,
        });
        return data.data.map((item) => new GolfCourse(item));
    }

    static async availability(courseId, month, year, member) {
        const { data } = await axios.get(
            `${config.api_url}courses/${courseId}/availability`,
            {
                params: {
                    month,
                    year,
                    member,
                },
            }
        );
        return data.data;
    }

    static async creditAvailability(
        courseId,
        month,
        year,
        member,
        booking = null
    ) {
        const { data } = await axios.get(
            `${config.api_url}courses/${courseId}/credit-availability`,
            {
                params: {
                    month,
                    year,
                    member,
                    booking,
                },
            }
        );
        return data.data;
    }

    static async creditPrice(courseId, member) {
        const { data } = await axios.get(
            `${config.api_url}courses/${courseId}/credit-price`,
            {
                params: {
                    member,
                },
            }
        );
        return data.data;
    }
}

export default GolfCourse;
