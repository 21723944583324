import React, { Component } from "react";
import {
    Input,
    Segment,
    Select,
    Button,
    Checkbox,
    Dropdown,
} from "semantic-ui-react";
import { connect } from "react-redux";

class Other extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            error: null,
            notification: null,
        };

        this.levels = [
            {
                key: 0,
                value: "black",
                text: "Black - ultra exclusive and not accessible, e.g. Queenwood, Loch Lomond or Riviera",
            },
            {
                key: 1,
                value: "platinum",
                text: "Platinum - highly exclusive, almost inaccessible and who won’t want to be publicised on the App, e.g. Sunningdale, Swinley, Centurion or Wisley",
            },
            {
                key: 2,
                value: "gold",
                text: "Gold - top 100 ranking course in world, world renowned 5 Star venue or highly private venue, e.g. Monte Rei, Adare Manor or Bearwood Lakes",
            },
            {
                key: 3,
                value: "silver",
                text: "Silver - top 100 rank course in country, 5 Star high quality venue or exclusive members club, e.g. Broadstone, Goodwood or Remedy Oak",
            },
            {
                key: 4,
                value: "bronze",
                text: "Bronze - County Qualifier, high 4-star venue/course or good quality private members club, e.g. Sandy Lodge, Luton Hoo or Frilford Heath",
            },
            {
                key: 5,
                value: "steel",
                text: "Steel - local Course, low 4-star or pay and play, e.g. Lilley Brook, Heythop Park or Collingtree Park",
            },
        ];

        this.types = [
            {
                key: 0,
                value: "public",
                text: "Public",
            },
            {
                key: 1,
                value: "private",
                text: "Private",
            },
            {
                key: 2,
                value: "proprietary",
                text: "Proprietary",
            },
        ];

        this.partnerStatus = [
            {
                key: 0,
                value: "No",
                text: "No",
            },
            {
                key: 1,
                value: "Partner",
                text: "Partner",
            },
            {
                key: 2,
                value: "Plus",
                text: "Plus",
            },
        ];

        this.facilities = [
            { key: 0, text: "Driving range", value: "Driving range" },
            { key: 1, text: "Hotel", value: "Hotel" },
            { key: 2, text: "Bar", value: "Bar" },
            { key: 3, text: "Restaurant", value: "Restaurant" },
            { key: 4, text: "Spa", value: "Spa" },
        ];

        this.affiliations = [
            { key: 0, text: "Troon", value: "Troon" },
            { key: 1, text: "IMG", value: "IMG" },
            { key: 2, text: "OB Sports", value: "OB Sports" },
            { key: 3, text: "Small Group", value: "Small Group" },
            { key: 4, text: "Independent", value: "Independent" },
        ];
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    gatherInput(property, value) {
        const { club } = this.props;
        club[property] = value;
        this.props.update(club);
    }

    gatherInputNumber(property, value) {
        const { club } = this.props;
        club[property] = parseInt(value) || 0;
        this.props.update(club);
    }

    onChangeLevel(event, result) {
        const { club } = this.props;
        club.level = result.value;
        this.props.update(club);
    }

    onChangeType(event, result) {
        const { club } = this.props;
        club.type = result.value;
        this.props.update(club);
    }

    onChangePartnerStatus(event, result) {
        const { club } = this.props;
        club.partnerStatus = result.value;
        this.props.update(club);
    }

    onChangeFacilities(event, props) {
        const { club } = this.props;
        const { value } = props;
        club.facilities = value;
        this.props.update(club);
    }

    onChangeAffiliations(event, props) {
        const { club } = this.props;
        const { value } = props;
        club.affiliations = value;
        this.props.update(club);
    }

    onChangeVatExempt(event) {
        const { club } = this.props;
        const checked = !event.target.previousSibling.checked;
        club.vatExempt = checked;
        this.props.update(club);
    }

    render() {
        return (
            <>
                <Segment vertical>
                    <Checkbox
                        toggle
                        label="Featured club"
                        checked={this.props.club.isFeatured}
                        onChange={(_, { checked }) =>
                            this.gatherInput("isFeatured", checked)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <Checkbox
                        toggle
                        label="Hide GolfBreaks advert"
                        checked={this.props.club.hideGolfBreaksAdvert}
                        onChange={(_, { checked }) =>
                            this.gatherInput("hideGolfBreaksAdvert", checked)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("level")}
                    <label className="with-input">Club level</label>
                    <Select
                        fluid
                        options={this.levels}
                        value={this.props.club.level}
                        onChange={(_, { value }) =>
                            this.gatherInput("level", value)
                        }
                        placeholder="Select a level"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("type")}
                    <label className="with-input">Club type</label>
                    <Select
                        fluid
                        options={this.types}
                        value={this.props.club.type}
                        onChange={(_, { value }) =>
                            this.gatherInput("type", value)
                        }
                        placeholder="Select a type"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("partnerStatus")}
                    <label className="with-input">Partner club status</label>
                    <Select
                        fluid
                        options={this.partnerStatus}
                        value={this.props.club.partnerStatus}
                        onChange={(_, { value }) =>
                            this.gatherInput("partnerStatus", value)
                        }
                        placeholder="Select a status"
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("complimentaryRounds")}
                    <label className="with-input">
                        Complimentary rounds of golf
                    </label>
                    <Input
                        fluid
                        name="complimentaryRounds"
                        value={this.props.club.complimentaryRounds ?? 0}
                        onChange={({ target }) =>
                            this.gatherInputNumber(
                                "complimentaryRounds",
                                target.value
                            )
                        }
                    />
                </Segment>
                <Segment vertical>
                    <Checkbox
                        toggle
                        label="Vat exempt"
                        checked={this.props.club.vatExempt}
                        onChange={(_, { checked }) =>
                            this.gatherInput("vatExempt", checked)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("website")}
                    <label className="with-input">Website</label>
                    <Input
                        fluid
                        name="website"
                        value={this.props.club.website ?? ""}
                        placeholder="Website"
                        onChange={({ target }) =>
                            this.gatherInput("website", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("facilities")}
                    <label className="with-input">Facilities</label>
                    <Dropdown
                        placeholder="Facilities (select as many as you like)"
                        value={this.props.club.facilities}
                        onChange={(_, { value }) =>
                            this.gatherInput("facilities", value)
                        }
                        fluid
                        multiple
                        selection
                        options={this.facilities}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("rankingLink")}
                    <label className="with-input">Ranking link</label>
                    <Input
                        fluid
                        name="rankingLink"
                        value={this.props.club.rankingLink ?? ""}
                        placeholder="Ranking link (e.g, top 100)"
                        onChange={({ target }) =>
                            this.gatherInput("rankingLink", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("affiliations")}
                    <label className="with-input">Affiliations</label>
                    <Dropdown
                        placeholder="Affiliations (select as many as you like)"
                        value={this.props.club.affiliations}
                        onChange={(_, { value }) =>
                            this.gatherInput("affiliations", value)
                        }
                        fluid
                        multiple
                        selection
                        options={this.affiliations}
                    />
                </Segment>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            <Button
                                size="large"
                                floated="right"
                                className="theme"
                                primary
                                onClick={this.props.save}
                                disabled={this.props.saving}
                                loading={this.props.saving}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Other);
