import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { Button, Modal } from "semantic-ui-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enGB from "date-fns/locale/en-GB";
import { Link } from "react-router-dom";

const locales = {
    "en-GB": enGB,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay,
    locales,
});

const statusColours = {
    requested: "#007ab5",
    booked: "#00b5ad",
    cancelled: "#7d2525",
};

export default ({ bookings, onChangeMonth }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);

    return (
        <>
            <Calendar
                localizer={localizer}
                events={bookings}
                onSelectEvent={(event) => setSelectedEvent(event)}
                eventPropGetter={(event) => {
                    return {
                        style: {
                            backgroundColor:
                                statusColours[event.resource.status],
                        },
                    };
                }}
                showAllEvents={true}
                views={["month", "week", "day"]}
                startAccessor="start"
                endAccessor="end"
                onNavigate={(date) =>
                    onChangeMonth(date.getMonth(), date.getFullYear())
                }
                style={{ height: (window.innerHeight / 100) * 80 }}
            />
            <div className="row">
                <div className="col">
                    {selectedEvent ? (
                        <Modal open={selectedEvent !== null}>
                            <Modal.Header>{selectedEvent.title}</Modal.Header>
                            <Modal.Content>
                                <div className="row">
                                    <div className="col">
                                        <Link
                                            to={`/tee-time-requests/${selectedEvent.resource._id}`}
                                        >
                                            <Button primary className="theme">
                                                View booking
                                            </Button>
                                        </Link>
                                        <p className="mt-3">
                                            <b>Status:</b>{" "}
                                            {selectedEvent.resource.status}
                                            <br />
                                            <b>Date created:</b>{" "}
                                            {format(
                                                new Date(
                                                    selectedEvent.resource.createdAt
                                                ),
                                                "EEEE, do LLLL yyyy"
                                            )}
                                            <br />
                                            <b>Date confirmed:</b>{" "}
                                            {selectedEvent.resource.bookedAt
                                                ? format(
                                                      new Date(
                                                          selectedEvent.resource.bookedAt
                                                      ),
                                                      "EEEE, do LLLL yyyy"
                                                  )
                                                : "Not yet confirmed"}
                                            <br />
                                            <b>Booking date:</b>{" "}
                                            {format(
                                                new Date(
                                                    selectedEvent.resource.bookingDate
                                                ),
                                                "EEEE, do LLLL yyyy"
                                            )}
                                            <br />
                                            <b>Member:</b>{" "}
                                            <Link
                                                to={`/members/${selectedEvent.resource.user._id}`}
                                            >
                                                {
                                                    selectedEvent.resource.user
                                                        .name
                                                }
                                            </Link>{" "}
                                            ({selectedEvent.resource.user.email}
                                            ),{" "}
                                            {
                                                selectedEvent.resource.user
                                                    .entity.name
                                            }
                                            <br />
                                            <b>No. of players:</b>{" "}
                                            {selectedEvent.resource.players}
                                            <br />
                                            <b>Course:</b>{" "}
                                            <Link
                                                to={`/golf-clubs/${selectedEvent.resource.course.club._id}`}
                                            >
                                                {
                                                    selectedEvent.resource
                                                        .course.longName
                                                }
                                            </Link>
                                            <br />
                                            <b>Credit booking:</b>{" "}
                                            {selectedEvent.resource
                                                .isCreditBooking
                                                ? "Yes"
                                                : "No"}
                                            <br />
                                            <b>Course group:</b>{" "}
                                            {selectedEvent.resource.course
                                                .group ? (
                                                <Link
                                                    to={`/groups/${selectedEvent.resource.course.group._id}`}
                                                >
                                                    {
                                                        selectedEvent.resource
                                                            .course.group.name
                                                    }
                                                </Link>
                                            ) : (
                                                "N/A"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    primary
                                    className="theme"
                                    content="Done"
                                    onClick={() => setSelectedEvent(null)}
                                />
                            </Modal.Actions>
                        </Modal>
                    ) : (
                        <p className="mt-3">
                            <b>Select an item to view more information</b>
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};
