import { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import format from "date-fns/format";
import LoadingPlaceholder from "../LoadingPlaceholder";
import StarSelectDisplaySimple from "../StarSelectDisplaySimple";
import DataTable from "../dataTable";

export default ({ club }) => {
    const [loadingStats, setLoadingStats] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [stats, setStats] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [total, setTotal] = useState(0);
    const [table, setTable] = useState({
        page: 1,
        term: "",
        sort: "createdAt",
        order: "asc",
        limit: 25,
        status: "APPROVED",
    });

    // state for pagination & filters. Need to always pass up a status of approved

    const fetchReviews = async (params) => {
        setLoadingTable(true);
        const reviews = await club.reviewsIndex(params);
        setReviews(reviews.data);
        setTotal(reviews.total);
        setTable(params);
        setLoadingTable(false);
    };

    const fetchStats = async (params) => {
        setLoadingStats(true);
        const stats = await club.reviewStats();
        setStats(stats);
        setLoadingStats(false);
    };

    useEffect(() => {
        fetchStats();
        fetchReviews(table);
    }, []);

    return (
        <>
            {loadingStats ? (
                <LoadingPlaceholder />
            ) : (
                <div className="row">
                    <div className="col">
                        <h2>Statistics</h2>
                        {stats.map((question, index) => {
                            return (
                                <Segment vertical key={index}>
                                    <p>
                                        <b>{question.name}</b>
                                    </p>
                                    <p>
                                        <small>{question.description}</small>
                                    </p>
                                    <StarSelectDisplaySimple
                                        score={question.rating}
                                        quantity={question.quantity}
                                    />{" "}
                                </Segment>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="row mt-3">
                <div className="col">
                    <h2>Club reviews</h2>
                    <DataTable
                        loading={loadingTable}
                        headers={[
                            { key: "createdAt", text: "Sent to member" },
                            { key: "submittedAt", text: "Completed by member" },
                            { key: "approvedAt", text: "Approved by Mashie" },
                            { key: "user", text: "Member" },
                            {
                                key: "reviewRating",
                                text: "MASHIE Member Rating",
                            },
                            { key: "actions", text: "Actions" },
                        ]}
                        data={reviews.map((review) => {
                            return {
                                createdAt: {
                                    value: format(
                                        new Date(review.createdAt),
                                        "dd/MM/yyyy, kk:mm"
                                    ),
                                },
                                submittedAt: {
                                    value: review.submittedAt
                                        ? format(
                                              new Date(review.submittedAt),
                                              "dd/MM/yyyy, kk:mm"
                                          )
                                        : "-",
                                },
                                approvedAt: {
                                    value: review.approvedAt
                                        ? format(
                                              new Date(review.approvedAt),
                                              "dd/MM/yyyy, kk:mm"
                                          )
                                        : "-",
                                },
                                user: {
                                    value: (
                                        <Link
                                            to={`/members/${review.user._id}`}
                                        >
                                            {review.user.name}
                                        </Link>
                                    ),
                                },
                                reviewRating: {
                                    value: review.rating ? (
                                        <StarSelectDisplaySimple
                                            score={review.rating}
                                        />
                                    ) : (
                                        "-"
                                    ),
                                },
                                actions: {
                                    value:
                                        review.status === "PENDING" ? (
                                            "-"
                                        ) : (
                                            <Link
                                                to={`/reviews/clubs/${review._id}`}
                                            >
                                                <Button
                                                    primary
                                                    className="theme"
                                                >
                                                    View
                                                </Button>
                                            </Link>
                                        ),
                                },
                            };
                        })}
                        totalRecords={total}
                        page={table.page}
                        onChange={fetchReviews}
                        sort={table.sort}
                        order={table.order}
                        limit={table.limit}
                        sortOptions={[
                            { text: "Sent to member", key: "createdAt" },
                            { text: "Completed by member", key: "submittedAt" },
                            { text: "Approved by Mashie", key: "approvedAt" },
                            { text: "Rejected by Mashie", key: "rejectedAt" },
                            { text: "Expired", key: "expiredAt" },
                            { text: "Club rating", key: "clubStars" },
                            { text: "Course rating", key: "courseStars" },
                            {
                                text: "Booking process rating",
                                key: "bookingProcessStars",
                            },
                            { text: "Credit value", key: "creditValue" },
                        ]}
                        pagination
                        hideSearch
                    />
                </div>
            </div>
        </>
    );
};
