import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Checkbox, Dropdown } from "semantic-ui-react";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import DataTable from "../../../assets/components/dataTable";

import NotificationActions from "../../../store/actions/notification";
import EntitySearchSelect from "../../../assets/components/EntitySearchSelect";
import Member from "../../../models/Member";
import moment from "moment";

class MembersIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            totalRecords: 1,
            filters: {
                active: false,
                membershipType: null,
                entity: [],
            },
            members: [],
            notification: null,
            error: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
        };

        if (this.props.user.has("LOGIN_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.membershipTypeOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Founder",
                value: "MASHIE_FOUNDER_MEMBERSHIP",
            },
            {
                key: 2,
                text: "Club",
                value: "MASHIE_CLUB_MEMBERSHIP",
            },
            {
                key: 3,
                text: "Links",
                value: "MASHIE_LINKS_MEMBERSHIP",
            },
            {
                key: 4,
                text: "Country",
                value: "MASHIE_COUNTRY_MEMBERSHIP",
            },
        ];
    }

    componentDidMount() {
        this.getMembers(this.state.table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    getMembers(params) {
        this.setState({ loading: true });
        Member.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    loading: false,
                    members: data.members,
                });
            })
            .catch(() => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load users, please try again"
                );
            });
    }

    showViewButton(user) {
        if (this.props.user.canAdministerUser(user)) {
            return (
                <Link to={`/members/${user._id}`}>
                    <Button primary className="theme">
                        View
                    </Button>
                </Link>
            );
        }
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        if (property === "membershipType") {
            filters.active = true;
        }
        table.page = 1;
        this.setState({ filters, table });
        this.getMembers(table);
    }

    table() {
        const headers = [
            { key: "name", text: "Name" },
            { key: "phone", text: "Phone" },
            { key: "email", text: "Email" },
            { key: "entity", text: "Entity" },
            { key: "company", text: "Company" },
            { key: "membership", text: "Membership" },
            { key: "membershipExpiry", text: "Membership expiry" },
            { key: "eventCreditBalance", text: "Event credit" },
            { key: "teeTimeCreditBalance", text: "Teetime credit" },
            { key: "county", text: "County" },
            { key: "showOnMemberDirectory", text: "On member directory?" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.members.map((user) => {
            const membership = user.getMembershipStatus();
            return {
                name: { value: user.name },
                phone: { value: user.phoneNumber },
                email: { value: user.email },
                entity: {
                    value: user.entity.entity.name,
                },
                company: {
                    value: user.company || "-",
                },
                membership: { value: membership.label },
                membershipExpiry: {
                    value: user.membershipExpiresAt
                        ? moment
                              .utc(user.membershipExpiresAt)
                              .format("DD/MM/YYYY")
                        : "-",
                },
                eventCreditBalance: {
                    value:
                        "£" +
                        String(parseInt(user.eventCreditBalance).toFixed(2)),
                },
                teeTimeCreditBalance: {
                    value:
                        "£" +
                        String(parseInt(user.teeTimeCreditBalance).toFixed(2)),
                },
                county: {
                    value: user.addresses[0]?.county || "-",
                },
                showOnMemberDirectory: {
                    value: user.showOnMemberDirectory ? "Yes" : "No",
                },
                actions: { value: this.showViewButton(user) },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getMembers.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "First name", key: "firstName" },
                    { text: "Surname", key: "surname" },
                    { text: "Membership expiry", key: "membershipExpiresAt" },
                    {
                        text: "Event credit balance",
                        key: "eventCreditBalance",
                    },
                    {
                        text: "Teetime credit balance",
                        key: "teeTimeCreditBalance",
                    },
                ]}
                pagination
            />
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header justify-content-between align-items-center mt-3">
                    <div className="col-3">
                        <h2>
                            {this.props.user.currentAdministrationEntity.name}{" "}
                            members
                        </h2>
                    </div>
                    <div className="col-3 text-right">
                        <Link to="/members/create">
                            <Button primary className="theme">
                                Add member
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Checkbox
                            toggle
                            label="Active memberships"
                            checked={this.state.filters.active}
                            onChange={(_, { checked }) =>
                                this.onChangeFilter("active", checked)
                            }
                        />
                        <Dropdown
                            selection
                            placeholder={"Membership type"}
                            options={this.membershipTypeOptions}
                            value={this.state.filters.membershipType}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("membershipType", value)
                            }
                            className={"ml-3"}
                        />
                        {this.props.user.has("MASHIE_ADMIN") && (
                            <EntitySearchSelect
                                multiple
                                placeholder="Filter by entity"
                                value={this.state.filters.entity}
                                onChange={(value) =>
                                    this.onChangeFilter("entity", value)
                                }
                                className="ml-3"
                            />
                        )}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col mt-3">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(MembersIndex);
