import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import NumberInput from "../NumberInput";
import membershipRoleKeys from "../../../utilities/membershipRoleKeys";

const CourseCreditPricing = ({ course, roles, onChange }) => {
    const onChangePrice = (roleId, price) => {
        const existing =
            course.creditPricing.find(({ role }) => role === roleId) ?? null;
        if (existing === null) {
            course.creditPricing.push({
                role: roleId,
                price,
            });
        } else {
            existing.price = price;
        }
        onChange(course);
    };

    return roles
        .filter(({ key }) => membershipRoleKeys.includes(key))
        .map((role, index) => {
            const price = course.creditPricing.find(
                (course) => course.role === role._id
            ) ?? {
                price: 0,
            };

            return (
                <Segment vertical key={index}>
                    <h3>{role.name}</h3>
                    <label className="with-input">Price</label>
                    <NumberInput
                        fluid
                        default={null}
                        min={1}
                        decimal
                        name={role._id}
                        value={price.price}
                        onChange={(value) => onChangePrice(role._id, value)}
                    />
                </Segment>
            );
        });
};

function mapStateToProps(state) {
    return {
        roles: state.roles,
    };
}

export default connect(mapStateToProps)(CourseCreditPricing);
