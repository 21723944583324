import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox, Dropdown } from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import { Button, Label } from "semantic-ui-react";
import logoPlaceholder from "../../assets/images/building.svg";
import NotificationActions from "../../store/actions/notification";
import GolfClub from "../../models/GolfClub";
import GolfClubMap from "../../assets/components/GolfClubMap";
import StarSelectDisplaySimple from "../../assets/components/StarSelectDisplaySimple";

const config = require("../../config");

class GolfClubsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            searchTerm: "",
            totalRecords: 0,
            golfClubs: [],
            clubCoordinates: [],
            error: false,
            filters: {
                hideNotVisible: true,
                partner: null,
                clubType: null,
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
        };

        if (this.props.user.has("LOGIN_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.partnerOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Partner",
                value: "Partner",
            },
            {
                key: 2,
                text: "Plus",
                value: "Plus",
            },
            {
                key: 3,
                text: "None",
                value: "No",
            },
        ];

        this.clubTypeOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Public",
                value: "public",
            },
            {
                key: 2,
                text: "Private",
                value: "private",
            },
            {
                key: 3,
                text: "Proprietary",
                value: "proprietary",
            },
        ];
    }

    componentDidMount() {
        this.getClubs(this.state.table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    async getClubCoordinates(params) {
        const clubCoordinates = await GolfClub.coordinates(params);
        this.setState({ clubCoordinates });
    }

    getClubs(params) {
        this.setState({ loading: true });
        this.getClubCoordinates(
            Object.assign({ term: params.term }, this.state.filters)
        );
        GolfClub.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    loading: false,
                    table: params,
                    golfClubs: data.clubs,
                    totalRecords: data.total,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load clubs, please try again"
                );
            });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getClubs(table);
    }

    logoImage(club) {
        if (typeof club.logo !== "undefined") {
            return config.content_url + "logos/" + club.logo;
        }

        return logoPlaceholder;
    }

    clubVisible(club) {
        if (typeof club.showForRoles !== "undefined") {
            if (club.showForRoles.length === 0) {
                return false;
            }

            return true;
        }

        return false;
    }

    table() {
        const headers = [
            { key: "name", text: "Club name" },
            { key: "country", text: "Country" },
            { key: "county", text: "Region" },
            { key: "bookingContact", text: "Booking contact" },
            { key: "bookingPhone", text: "Booking phone" },
            { key: "bookingEmail", text: "Booking email" },
            { key: "clubType", text: "Club type" },
            { key: "partnerStatus", text: "Partner club" },
            { key: "averageStarRating", text: "Average rating" },
            { key: "visible", text: "Visibility" },
            { key: "actions", text: "Actions" },
        ];
        const data = this.state.golfClubs.map((club) => {
            const booking =
                club.contacts.find(
                    (contact) => contact.position === "Booking"
                ) || null;
            return {
                name: { value: club.name, search: club.name },
                country: { value: club.country, search: club.country },
                county: { value: club.county, search: club.county },
                bookingContact: {
                    value: booking ? booking.name : "-",
                    search: booking ? booking.name : "-",
                },
                bookingPhone: {
                    value: booking ? booking.phone : "-",
                    search: booking ? booking.phone : "-",
                },
                bookingEmail: {
                    value: booking ? booking.email : "-",
                    search: booking ? booking.email : "-",
                },
                clubType: { value: club.type, search: club.type },
                partnerStatus: {
                    value: club.partnerStatus
                        ? club.partnerStatus.toUpperCase()
                        : null,
                    search: club.partnerStatus,
                },
                averageStarRating: {
                    value: club.averageStarRating ? (
                        <StarSelectDisplaySimple
                            score={club.averageStarRating}
                            quantity={club.numberOfRatings}
                        />
                    ) : (
                        "-"
                    ),
                },
                visible: {
                    value: this.clubVisible(club) ? (
                        <Label color="green">Visible</Label>
                    ) : (
                        <Label color="red">Not visible</Label>
                    ),
                },
                actions: {
                    value: (
                        <Link to={`/golf-clubs/${club._id}`}>
                            <Button primary className="theme">
                                View
                            </Button>
                        </Link>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getClubs.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Name", key: "name" },
                    { text: "Region", key: "county" },
                    { text: "Average rating", key: "averageStarRating" },
                    { text: "No. of ratings", key: "numberOfRatings" },
                    { text: "Type", key: "type" },
                    { text: "Partner status", key: "partnerStatus" },
                ]}
                pagination
            />
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div
                        style={{
                            width: "100%",
                            height: 400,
                            marginLeft: 20,
                            marginRight: 20,
                            borderRadius: 20,
                            overflow: "hidden",
                        }}
                    >
                        <GolfClubMap
                            history={this.props.history}
                            golfClubs={this.state.clubCoordinates}
                        />
                    </div>
                </div>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-8">
                        <h2>Golf clubs</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 text-right">
                        <Link to="/golf-clubs/create">
                            <Button primary className="theme">
                                Add club
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Checkbox
                            toggle
                            label="Only show visible clubs"
                            checked={this.state.filters.hideNotVisible}
                            onChange={(_, { checked }) =>
                                this.onChangeFilter("hideNotVisible", checked)
                            }
                        />
                        <Dropdown
                            selection
                            placeholder={"Partner status"}
                            options={this.partnerOptions}
                            value={this.state.filters.partner}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("partner", value)
                            }
                            className={"ml-3"}
                        />
                        <Dropdown
                            selection
                            placeholder={"Club type"}
                            options={this.clubTypeOptions}
                            value={this.state.filters.clubType}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("clubType", value)
                            }
                            className={"ml-3"}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(GolfClubsIndex);
