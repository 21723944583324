import React, { Component } from "react";
import { Input, Segment, Checkbox, Select, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";

import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import EntitySearchSelect from "../../assets/components/EntitySearchSelect";
import Offer from "../../models/Offer";
import HtmlEditor from "../../assets/components/HtmlEditor";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            notification: null,
            offer: new Offer(),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.actionTypes = [
            { key: 0, text: "Link", value: "link" },
            { key: 1, text: "Phone", value: "phone" },
            { key: 2, text: "Email", value: "email" },
        ];

        this.categories = [
            { key: 0, text: "Golf", value: "golf" },
            { key: 1, text: "Networking", value: "networking" },
            { key: 2, text: "Hotel", value: "hotel" },
            { key: 3, text: "Sports", value: "sports" },
            { key: 4, text: "Event Management", value: "event management" },
            { key: 5, text: "Lifestyle", value: "lifestyle" },
            { key: 6, text: "Drinks", value: "drinks" },
            { key: 7, text: "Travel", value: "travel" },
            { key: 8, text: "Financial", value: "financial" },
            { key: 9, text: "Design & Print", value: "design and print" },
            {
                key: 10,
                text: "Information Technology",
                value: "information technology",
            },
            { key: 11, text: "Miscellaneous", value: "miscellaneous" },
        ];
    }

    onChangeText(property, value) {
        const { offer } = this.state;
        offer[property] = value;
        this.setState({ offer });
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    gatherInput(event) {
        const { offer } = this.state;
        offer[event.target.name] = event.target.value;
        this.setState({ offer });
    }

    selectProfileImage() {
        const image = document.getElementById("profile-image-upload");
        image.click();
    }

    save() {
        this.setState({ saving: true });
        this.state.offer
            .store()
            .then((response) => {
                this.props.history.push("/offers");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save offer");
                this.handleValidationErrors(error);
            });
    }

    onChangeCategories(event, props) {
        const { offer } = this.state;
        const { value } = props;
        offer.categories = value;
        this.setState({ offer });
    }

    onChangeExcludedEntities(value) {
        const { offer } = this.state;
        offer.excludedEntities = value;
        this.setState({ offer });
    }

    onChangeActionType(event, props) {
        const { offer } = this.state;
        const { value } = props;
        offer.actionType = value;
        this.setState({ offer });
    }

    onChangTogglePartner() {
        const { offer } = this.state;
        offer.isPartner = !offer.isPartner;
        this.setState({ offer });
    }

    offerDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the offer's details.</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.offer.name || ""}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Partner brand?</label>
                    <p>
                        <small>
                            Partner brands will appear on the Partner Brands
                            page within the app
                        </small>
                    </p>
                    <Checkbox
                        toggle
                        checked={this.state.offer.isPartner}
                        onChange={this.onChangTogglePartner.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("actionType")}
                    <label className="with-input">Action type</label>
                    <Select
                        placeholder="Select an action"
                        value={this.state.offer.actionType}
                        onChange={this.onChangeActionType.bind(this)}
                        fluid
                        options={this.actionTypes}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("actionContent")}
                    <label className="with-input">
                        Action content (link, phone number, url)
                    </label>
                    <Input
                        fluid
                        name="actionContent"
                        value={this.state.offer.actionContent || ""}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("excludedEntities")}
                    <label className="with-input">Excluded entities</label>
                    <p>
                        <small>
                            Members attached to the following entities will{" "}
                            <i>not</i> see this offer
                        </small>
                    </p>
                    <EntitySearchSelect
                        fluid
                        multiple
                        value={this.state.offer.excludedEntities}
                        onChange={(value) =>
                            this.onChangeExcludedEntities(value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("categories")}
                    <label className="with-input">Categories</label>
                    <Select
                        placeholder="Interests (select as many as you like)"
                        value={this.state.offer.categories}
                        onChange={this.onChangeCategories.bind(this)}
                        fluid
                        multiple
                        selection
                        options={this.categories}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("description")}
                    <label className="with-input">Description</label>
                    <HtmlEditor
                        content={this.state.offer.description}
                        onChange={(value) =>
                            this.onChangeText("description", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("clubDetail")}
                    <label className="with-input">Club offer details</label>
                    <HtmlEditor
                        content={this.state.offer.clubDetail}
                        onChange={(value) =>
                            this.onChangeText("clubDetail", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("linksDetail")}
                    <label className="with-input">Links offer details</label>
                    <HtmlEditor
                        content={this.state.offer.linksDetail}
                        onChange={(value) =>
                            this.onChangeText("linksDetail", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("countryDetail")}
                    <label className="with-input">Country offer details</label>
                    <HtmlEditor
                        content={this.state.offer.countryDetail}
                        onChange={(value) =>
                            this.onChangeText("countryDetail", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container">
                <div className="row skinny">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.offerDetailsTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
