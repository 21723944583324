import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "../../assets/components/golfClub/style.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Role from "../../models/Role";
import GolfClub from "../../models/GolfClub";
import GolfCourse from "../../models/GolfCourse";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Detials from "../../assets/components/golfClub/Details";
import Images from "../../assets/components/golfClub/Images";
import Description from "../../assets/components/golfClub/Description";
import Availability from "../../assets/components/golfClub/Availability";
import Courses from "../../assets/components/golfClub/Courses";
import Notes from "../../assets/components/golfClub/Notes";
import Other from "../../assets/components/golfClub/Other";
import Contacts from "../../assets/components/golfClub/Contacts";
import Management from "../../assets/components/golfClub/Management";
import Location from "../../assets/components/golfClub/Location";
import Reviews from "../../assets/components/golfClub/Reviews";
import CenterLoader from "../../assets/components/CenterLoader";
import ImageCropper from "../../assets/components/ImageCropper";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            club: null,
            loading: true,
            canSendInternalMessage: true,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getClub();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    update(club) {
        this.setState({ club });
    }

    async getClub() {
        try {
            const club = await GolfClub.show(this.props.match.params.id);
            club.courses = club.courses.map((course) => new GolfCourse(course));
            const roles = await Role.index();
            this.props.dispatch({ type: "STORE_ROLES", payload: roles.data });
            this.setState({ loading: false, club });
        } catch (error) {
            this.setState({ error: error.response.status });
            this.notification("error", "Failed to load club, please try again");
        }
    }

    loaded() {
        return this.state.loading === false;
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
        this.props.dispatch(ValidationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    onChangeLogo(base64ImageString) {
        const { club } = this.state;
        club.updateLogo(base64ImageString).then(({ logo }) => {
            club.logo = logo;
            this.setState({ club });
        });
    }

    save() {
        this.setState({ saving: true });
        this.state.club
            .update()
            .then(() => {
                this.setState({ saving: false, canSendInternalMessage: true });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "Changes saved");
            })
            .catch((error) => {
                this.setState({ saving: false, canSendInternalMessage: true });
                this.notification("error", "Failed to save club");
                this.handleValidationErrors(error);
            });
    }

    notesTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Internal notes</h3>
                <Notes club={this.state.club} update={this.update.bind(this)} />
            </div>
        );
    }

    availabilityTab() {
        return (
            <div className={styles.mainSegment}>
                <Availability
                    club={this.state.club}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                />
            </div>
        );
    }

    otherTab() {
        return (
            <div className={styles.mainSegment}>
                <div className="row">
                    <div className="col">
                        <h3>Other information</h3>
                    </div>
                </div>
                <Other
                    club={this.state.club}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                />
            </div>
        );
    }

    contactsTab() {
        return (
            <div className={styles.mainSegment}>
                <div className="row">
                    <div className="col">
                        <h3>Contacts</h3>
                    </div>
                </div>
                <Contacts
                    club={this.state.club}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                />
            </div>
        );
    }

    coursesTab() {
        return (
            <div className={styles.mainSegment}>
                <div className="row">
                    <div className="col">
                        <h3>Courses</h3>
                    </div>
                    s
                </div>
                <Courses
                    club={this.state.club}
                    update={this.update.bind(this)}
                />
            </div>
        );
    }

    descriptionTab() {
        return (
            <div className={styles.mainSegment}>
                <div className="row">
                    <div className="col">
                        <h3>Description</h3>
                        <Description
                            club={this.state.club}
                            update={this.update.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    clubImagesTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Images</h3>
                <Images
                    club={this.state.club}
                    update={this.update.bind(this)}
                />
            </div>
        );
    }

    clubBasic() {
        return (
            <div className={styles.topSegment + " skinny"}>
                <div className="row skinny">
                    <div className="col text-center">
                        <img
                            className="profile-logo-lg"
                            src={this.state.club.logoUrl}
                        />
                        <ImageCropper onChange={this.onChangeLogo.bind(this)} />
                        <h3>{this.state.club.name}</h3>
                    </div>
                </div>
            </div>
        );
    }

    clubDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <Detials
                    club={this.state.club}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                />
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                {this.clubBasic()}
                <div className="row skinny">
                    <div className="col">
                        <Tabs defaultActiveKey="details" id="tabs">
                            <Tab eventKey="details" title="Details">
                                {this.clubDetailsTab()}
                            </Tab>
                            <Tab eventKey="images" title="Images">
                                {this.clubImagesTab()}
                            </Tab>
                            <Tab eventKey="description" title="Description">
                                {this.descriptionTab()}
                            </Tab>
                            <Tab eventKey="availability" title="Availability">
                                {this.availabilityTab()}
                            </Tab>
                            <Tab eventKey="courses" title="Courses">
                                {this.coursesTab()}
                            </Tab>
                            <Tab eventKey="notes" title="Internal notes">
                                {this.notesTab()}
                            </Tab>
                            <Tab eventKey="contacts" title="Contacts">
                                {this.contactsTab()}
                            </Tab>
                            <Tab eventKey="other" title="Other">
                                {this.otherTab()}
                            </Tab>
                            <Tab eventKey="location" title="Location">
                                <div className={styles.mainSegment}>
                                    <h2>Location</h2>
                                    <Location
                                        club={this.state.club}
                                        update={this.update.bind(this)}
                                        save={this.save.bind(this)}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="reviews" title="Reviews">
                                <div className={styles.mainSegment}>
                                    <Reviews club={this.state.club} />
                                </div>
                            </Tab>
                            <Tab eventKey="management" title="Manage">
                                <div className={styles.mainSegment}>
                                    <h2>Manage</h2>
                                    <Management club={this.state.club} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
