import axios from "axios";
const config = require("../config");

class CourseGroup {
    constructor(properties) {
        this.roleBlockList = [];
        this.entityBlockList = [];
        this.availability = {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        };
        Object.assign(this, properties);
    }

    static async index(params) {
        const response = await axios.get(config.api_url + "course-groups", {
            params,
        });
        return {
            groups: response.data.data.map((group) => new CourseGroup(group)),
            total: response.data.total,
        };
    }

    static async list(params = {}) {
        const response = await axios.get(
            config.api_url + "course-groups/list",
            { params }
        );
        return response.data.data.map((group) => new CourseGroup(group));
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "course-groups/" + id
        );
        return new CourseGroup(response.data.data);
    }

    store() {
        return axios.post(config.api_url + "course-groups", {
            name: this.name,
            entityBlockList: this.entityBlockList,
            roleBlockList: this.roleBlockList,
            availability: this.availability,
        });
    }

    delete() {
        return axios.delete(config.api_url + "course-groups/" + this._id);
    }

    update() {
        return axios.put(config.api_url + "course-groups/" + this._id, {
            name: this.name,
            entityBlockList: this.entityBlockList,
            roleBlockList: this.roleBlockList,
            availability: this.availability,
        });
    }
}

export default CourseGroup;
