import axios from "axios";
import profileImagePlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class EntityAdmin {
    constructor(properties) {
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.addresses = [];
        this.roles = [];
        this.permissions = [];
        this.allPermissions = [];
        Object.assign(this, properties);
    }

    has(key) {
        if (typeof this.roles !== "undefined") {
            return this.allPermissions.some(
                (permission) => permission.key === key
            );
        }

        return false;
    }

    hasRole(key) {
        if (typeof this.roles !== "undefined") {
            return this.roles.some((role) => role.role.key === key);
        }

        return false;
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "entity-admins/" + id
        );
        return new EntityAdmin(response.data.data);
    }

    static async index(params = {}) {
        const { data } = await axios.get(config.api_url + "entity-admins", {
            params,
        });
        return {
            admins: data.data.map((admin) => new EntityAdmin(admin)),
            total: data.total,
        };
    }

    static async list() {
        const { data } = await axios.get(config.api_url + "entity-admins/list");
        return data.data.map((admin) => new EntityAdmin(admin));
    }

    async store() {
        const response = await axios.post(config.api_url + "/entity-admins", {
            firstName: this.firstName,
            surname: this.surname,
            emailAddresses: this.emailAddresses.map((email) => ({
                email: email.email,
                emailType: email.emailType,
                primary: email.primary,
            })),
        });

        return new EntityAdmin(response.data.data);
    }

    updateProfileImage(profileImage) {
        return axios.put(
            config.api_url + "entity-admins/" + this._id + "/profile-image",
            {
                profileImage,
            }
        );
    }

    async update() {
        const response = await axios.put(
            config.api_url + "entity-admins/" + this._id,
            {
                firstName: this.firstName,
                surname: this.surname,
                emailAddresses: this.emailAddresses.map((email) => ({
                    email: email.email,
                    emailType: email.emailType,
                    primary: email.primary,
                })),
            }
        );

        return new EntityAdmin(response.data.data);
    }

    async updateRoles() {
        const response = await axios.put(
            config.api_url + "entity-admins/" + this._id + "/roles",
            {
                roles: this.roles.map((role) => ({
                    role: role.role._id,
                    expiresAt: role.expiresAt,
                    activatesAt: role.activatesAt,
                    duration: role.duration,
                    cost: role.cost,
                    invoiceNumber: role.invoiceNumber,
                    paid: role.paid,
                    eventCredit: role.eventCredit,
                    teeTimeCredit: role.teeTimeCredit,
                    deletedAt: role.deletedAt,
                })),
            }
        );

        return response.data.data;
    }

    delete() {
        return axios.delete(config.api_url + "entity-admins/" + this._id);
    }

    get profileImageUrl() {
        if (typeof this.profileImage !== "undefined") {
            return config.content_url + "profiles/" + this.profileImage;
        }

        return profileImagePlaceholder;
    }
}

export default EntityAdmin;
