import React, { Component } from "react";
import { Input, Segment, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import EventSeries from "../../models/EventSeries";

class EventsSeriesCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            eventSeries: new EventSeries({ isHidden: false }),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    changeImage(evt) {
        const { target } = evt;
        const { eventSeries } = this.state;
        const fileReader = new FileReader();

        if (typeof target.files[0] !== "undefined") {
            fileReader.readAsDataURL(target.files[0]);
            fileReader.onload = () => {
                eventSeries.image = fileReader.result;
                this.setState({ eventSeries });
            };
        }
    }

    addImage() {
        return (
            <div className="row">
                <div className="col">
                    <Segment vertical>
                        <input
                            type="file"
                            id="imageFileInput"
                            className="hidden"
                            onChange={this.changeImage.bind(this)}
                        />
                        <Button
                            primary
                            className="theme center"
                            onClick={() =>
                                document
                                    .getElementById("imageFileInput")
                                    .click()
                            }
                        >
                            Select image
                        </Button>
                    </Segment>
                </div>
            </div>
        );
    }

    showImage() {
        const image = this.state.eventSeries.image;
        if (typeof image === "undefined") {
            return <p>Please select an image for this event series</p>;
        }

        return (
            <img
                className={styles.eventImagePreview}
                src={this.state.eventSeries.image}
            />
        );
    }

    store() {
        this.setState({ saving: true });
        this.state.eventSeries
            .store()
            .then((response) => {
                this.notification("success", "Event series created");
                this.props.history.push("/events/series");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to create event series");
                this.handleValidationErrors(error);
            });
    }

    onChangeName(value) {
        const { eventSeries } = this.state;
        eventSeries.name = value;
        this.setState({ eventSeries });
    }

    onChangeIsHidden() {
        const eventSeries = this.state.eventSeries;
        eventSeries.isHidden = !eventSeries.isHidden;
        this.setState({ eventSeries });
    }

    seriesDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Create a new event series</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">
                        Name <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.eventSeries.name || ""}
                        onChange={({ target }) =>
                            this.onChangeName(target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <h3>Image</h3>
                    {this.errorsFor("image")}
                    {this.showImage()}
                    {this.addImage()}
                </Segment>
                <Segment vertical>
                    <label className="with-input">Hidden</label>
                    <p>
                        Checking this toggle will hide this series from the
                        filter list
                    </p>
                    <Checkbox
                        toggle
                        checked={this.state.eventSeries.isHidden}
                        onChange={this.onChangeIsHidden.bind(this)}
                    />
                </Segment>
            </div>
        );
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container skinny">
                <div className="row skinny">
                    <div className="col">
                        <h2>Create event series</h2>
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.seriesDetailsTab()}
                            </Tab>
                        </Tabs>
                        <Button
                            size="large"
                            floated="right"
                            className="theme"
                            primary
                            onClick={this.store.bind(this)}
                            disabled={this.state.saving}
                            loading={this.state.saving}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(EventsSeriesCreate);
