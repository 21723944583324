import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Input,
    Segment,
    Form,
    TextArea,
    Modal,
    Button,
} from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";

import NotificationActions from "../../store/actions/notification";
import CenterLoader from "../../assets/components/CenterLoader";
import Notes from "../../assets/components/eventBooking/Notes";
import EventBooking from "../../models/EventBooking";
import moment from "moment";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import User from "../../models/User";

class bookingsShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingEventBooking: true,
            notification: null,
            error: null,
            eventBooking: new EventBooking(),
            initalEventBooking: {},
            open: false,
            creditError: false,
            bookingErrors: [],
            numberOfPlayersError: "",
        };
    }

    getEventBooking() {
        const { id } = this.props.match.params;
        EventBooking.getById(id)
            .then((eventBooking) => {
                this.setState({
                    eventBooking,
                    loadingEventBooking: false,
                    initalEventBooking: JSON.parse(
                        JSON.stringify(eventBooking)
                    ),
                });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification(
                    "error",
                    "Failed to get event booking, please try again"
                );
            });
    }

    addPlayerDetails() {
        const eventBooking = this.state.eventBooking;
        eventBooking.playerDetails.push({ name: "", handicap: "0" });
        this.setState({ eventBooking: eventBooking });
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.data.key === "notEnoughPlayers") {
            this.setState({ numberOfPlayersError: error.response.data.errors });
            return;
        }

        if (error.response.status === 422) {
            this.setState({
                bookingErrors: error.response.data.errors,
                numberOfPlayersError: "",
            });
        }

        if (error.response.status === 403) {
            this.setState({ creditError: true, open: true });
        }
    }

    save() {
        this.setState({ saving: true });
        this.state.eventBooking
            .update()
            .then((response) => {
                this.setState({
                    saving: false,
                    eventBooking: new EventBooking(response.data.data),
                    initalEventBooking: new EventBooking(response.data.data),
                    open: false,
                    bookingErrors: [],
                    numberOfPlayersError: "",
                });
                this.notification("success", "Event booking saved");
            })
            .catch((error) => {
                this.setState({ saving: false, open: false });
                this.notification("error", "Failed to save event booking");
                this.handleValidationErrors(error);
            });
    }

    onChangeNumberOfPlayers(value) {
        const eventBooking = this.state.eventBooking;
        if (this.state.eventBooking.event.noGuests === true) {
            eventBooking.players = 1;
        } else {
            eventBooking.players = parseInt(value);
        }
        this.setState({ eventBooking });
    }

    eventBookingDetailsTab() {
        return (
            <Tab eventKey="details" title="Details">
                <div className={styles.mainSegment}>
                    <h3>Details</h3>
                    <p>Edit the event bookings details.</p>
                    <Segment vertical>
                        <label className="with-input">No. of players</label>
                        <Input
                            placeholder="No. of players"
                            type="number"
                            name="players"
                            onChange={({ target }) =>
                                this.onChangeNumberOfPlayers(target.value)
                            }
                            value={this.state.eventBooking.players || 0}
                            disabled={this.state.eventBooking.event.noGuests}
                        />
                    </Segment>
                    <p className={styles.playerError}>
                        {this.state.numberOfPlayersError}
                    </p>
                    {this.listPlayerDetails()}
                    <div className="mt-4">
                        <Button
                            primary
                            className="theme"
                            onClick={this.addPlayerDetails.bind(this)}
                        >
                            Add player details
                        </Button>
                    </div>
                    <Segment vertical>
                        <Button
                            size="large"
                            floated="right"
                            className="theme"
                            primary
                            onClick={() => this.setState({ open: true })}
                            disabled={this.state.saving}
                            loading={this.state.saving}
                        >
                            Save
                        </Button>
                    </Segment>
                </div>
            </Tab>
        );
    }

    componentDidMount() {
        this.getEventBooking();
    }

    loaded() {
        return this.state.loadingEventBooking === false;
    }

    onChangePlayerDetailName(event) {
        const { value } = event.target;
        const { name } = event.target;
        const { eventBooking } = this.state;
        eventBooking.playerDetails[name].name = value;
        this.setState({ eventBooking });
    }

    onChangePlayerDetailHandicap(event) {
        const { value } = event.target;
        const { name } = event.target;
        const { eventBooking } = this.state;
        eventBooking.playerDetails[name].handicap = value;
        this.setState({ eventBooking });
    }

    removePlayerDetail(index) {
        const { eventBooking } = this.state;
        eventBooking.playerDetails.splice(index, 1);
        this.setState({ eventBooking });
    }

    onChangeFreeTransaction(event) {
        const { eventBooking } = this.state;
        const checked = !event.target.previousSibling.checked;
        eventBooking.isFree = checked;
        this.setState({ eventBooking });
    }

    disableButton() {
        if (this.state.creditError === true) {
            if (this.state.eventBooking.isFree) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    renderModal() {
        if (this.state.eventBooking.event) {
            const newPlayersTotal = parseFloat(
                this.state.eventBooking.players -
                    this.state.initalEventBooking.players
            );
            const payableAmount =
                0 + this.state.eventBooking.event.price * 1.2 * newPlayersTotal;
            const calculateRemainingCredit =
                payableAmount - this.state.eventBooking.user.eventCreditBalance;

            return (
                <Modal open={this.state.open}>
                    <Modal.Header>
                        Please confirm changes to booking
                    </Modal.Header>
                    <Modal.Content>
                        <div className="row">
                            <div className="col text-center">
                                <h3>
                                    Below are the changes to the current
                                    booking:
                                </h3>
                                <div className={styles.modalRow}>
                                    <div className={styles.modalColumn}>
                                        <p className={styles.modalHeading}>
                                            Previous number of players
                                        </p>
                                        <p className={styles.modalNumber}>
                                            {
                                                this.state.initalEventBooking
                                                    .players
                                            }
                                        </p>
                                    </div>
                                    <div className={styles.modalColumn}>
                                        <p className={styles.modalHeading}>
                                            New number of players
                                        </p>
                                        <p className={styles.modalNumber}>
                                            {this.state.eventBooking.players}
                                        </p>
                                    </div>
                                </div>
                                {!this.state.creditError === true && (
                                    <div>
                                        <p className={styles.modalFooterText}>
                                            {this.createTransactionText(
                                                this.state.initalEventBooking,
                                                this.state.eventBooking
                                            )}
                                        </p>
                                    </div>
                                )}
                                {this.state.creditError === true && (
                                    <>
                                        <p
                                            className={
                                                styles.modalFooterErrorText
                                            }
                                        >
                                            This member doesn't have enough
                                            credit for this transaction.
                                        </p>
                                        <p className={styles.generalText}>
                                            <span className={styles.bold}>
                                                {
                                                    this.state.eventBooking.user
                                                        .name
                                                }
                                            </span>{" "}
                                            currently has{" "}
                                            <span className={styles.bold}>
                                                £
                                                {
                                                    this.state.eventBooking.user
                                                        .eventCreditBalance
                                                }
                                            </span>{" "}
                                            in their event credit.
                                        </p>
                                        <p className={styles.generalText}>
                                            They will need{" "}
                                            <span className={styles.bold}>
                                                £{calculateRemainingCredit}
                                            </span>{" "}
                                            adding to their account to update
                                            their booking for this event.
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content="Cancel"
                            onClick={() =>
                                this.setState({
                                    open: false,
                                    creditError: false,
                                })
                            }
                        />
                        <Button
                            primary
                            className="theme"
                            content="Confirm"
                            disabled={this.disableButton()}
                            onClick={this.save.bind(this)}
                        />
                    </Modal.Actions>
                </Modal>
            );
        }
    }

    getBookingPrice(previousBooking, currentBooking) {
        const playerDifference = Math.abs(
            previousBooking.players - currentBooking.players
        );
        return parseFloat(currentBooking.event.price * 1.2) * playerDifference;
    }

    listPlayerDetails() {
        const errorObject = this.state.bookingErrors;
        return this.state.eventBooking.playerDetails.map((player, index) => {
            return (
                <div className="row mt-3" key={index}>
                    <div className="col-7">
                        <label className="with-input">Player name</label>
                        <Input
                            fluid
                            placeholder="Player name"
                            name={index}
                            onChange={this.onChangePlayerDetailName.bind(this)}
                            value={player.name}
                        />
                        {errorObject[`playerDetails.${index}.name`] && (
                            <p className={styles.bookingError}>
                                {
                                    errorObject[`playerDetails.${index}.name`]
                                        .message
                                }
                            </p>
                        )}
                    </div>
                    <div className="col-3">
                        <label className="with-input">Player handicap</label>
                        <Input
                            fluid
                            placeholder="Player handicap"
                            name={index}
                            onChange={this.onChangePlayerDetailHandicap.bind(
                                this
                            )}
                            value={player.handicap}
                        />
                    </div>
                    <div className="col-2 d-flex align-items-end text-right justify-content-end">
                        <Button
                            primary
                            className="red"
                            onClick={() => this.removePlayerDetail(index)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    createTransactionText(previousBooking, currentBooking) {
        if (currentBooking.players > previousBooking.players) {
            const newCost = this.getBookingPrice(
                this.state.initalEventBooking,
                this.state.eventBooking
            );
            return (
                <>
                    <span className={styles.bold}>
                        {currentBooking.user.name}
                    </span>{" "}
                    will be billed for{" "}
                    <span className={styles.bold}>£{newCost}</span>
                </>
            );
        } else if (currentBooking.players < previousBooking.players) {
            const newCost = this.getBookingPrice(
                this.state.initalEventBooking,
                this.state.eventBooking
            );
            return (
                <>
                    <span className={styles.bold}>
                        {currentBooking.user.name}
                    </span>{" "}
                    will be refunded{" "}
                    <span className={styles.bold}>£{newCost}</span>
                </>
            );
        } else {
            return "There are no changes to this current event booking";
        }
    }

    update(eventBooking) {
        this.setState({ eventBooking });
    }

    notesTab() {
        if (this.props.user.isMashieAdmin()) {
            return (
                <Tab eventKey="notes" title="Internal notes">
                    <div className={styles.mainSegment}>
                        <h2>Notes</h2>
                        <Notes
                            booking={this.state.eventBooking}
                            update={this.update.bind(this)}
                        />
                    </div>
                </Tab>
            );
        }
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        const userLink =
            process.env.FRONTEND_URL +
            "/members/" +
            this.state.eventBooking.user._id;

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <h2>Event Booking</h2>
                </div>
                <div className="row skinny mb-3">
                    <div className="col-md-6">
                        <div className={styles.choice}>
                            <p>
                                <span>
                                    <b>Members Name: </b>
                                    <Link
                                        className={styles.smallText}
                                        to={`/members/${this.state.eventBooking.user._id}`}
                                    >
                                        {this.state.eventBooking.user.name}
                                    </Link>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <b>Event Name: </b>
                                    {this.state.eventBooking.event.name}
                                </span>
                            </p>
                            <p>
                                <span>
                                    <b>Event Date: </b>
                                    {moment(
                                        this.state.eventBooking.event.date
                                    ).format("Do MMMM YYYY")}
                                </span>
                            </p>
                            <p>
                                <span>
                                    <b>Booked Price: </b>£
                                    {this.state.eventBooking.bookedPrice} (£
                                    {(
                                        this.state.eventBooking.bookedPrice *
                                        1.2
                                    ).toFixed(2)}{" "}
                                    inc VAT)
                                </span>
                            </p>
                            <p>
                                <span>
                                    <b>Current Price: </b>£
                                    {this.state.eventBooking.event.price} (£
                                    {(
                                        this.state.eventBooking.event.price *
                                        1.2
                                    ).toFixed(2)}{" "}
                                    inc VAT)
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row skinny">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            {this.eventBookingDetailsTab()}
                            {this.notesTab()}
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                    {this.renderModal()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(bookingsShow);
