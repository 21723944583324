import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Label, Icon, Button, Input } from "semantic-ui-react";
import MembershipTypeSelection from "./MembershipTypeSelection";
import moment from "moment";

class Memberships extends Component {
    constructor(props) {
        super(props);

        this.types = [
            "MASHIE_COUNTRY_MEMBERSHIP",
            "MASHIE_LINKS_MEMBERSHIP",
            "MASHIE_CLUB_MEMBERSHIP",
            "MASHIE_TRIAL_MEMBERSHIP",
            "MASHIE_FOUNDER_MEMBERSHIP",
        ];

        this.state = {
            editing: null,
            member: props.member,
        };
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    onChangeInput(event) {
        const { member } = this.state;
        member[event.target.name] = event.target.value;
        this.setState({ member });
    }

    deleteMembership(index) {
        const confirm = window.confirm(
            "Are you sure you want to remove this membership?"
        );
        if (confirm === true) {
            const { member } = this.state;
            this.setState({ member });
            member.roles.splice(index, 1);
            this.setState({ member });
            this.props.saveRoles();
        }
    }

    expiredStatus(role) {
        if (moment.utc(role.expiresAt).isBefore(moment.utc())) {
            return <Label color="red">Expired</Label>;
        }

        if (moment.utc(role.activatesAt).isAfter(moment.utc())) {
            return <Label color="blue">Not yet active</Label>;
        }

        return <Label color="green">Active</Label>;
    }

    editRole(index) {
        this.setState({ editing: index });
    }

    addMembership() {
        const defaultRole = this.props.roles.find(
            (role) => role.key === "MASHIE_COUNTRY_MEMBERSHIP"
        );

        const activatesAt = moment.utc().date(1).startOf("day");
        const expiresAt = activatesAt
            .clone()
            .add(1, "year")
            .subtract(1, "day")
            .endOf("day");

        const newRole = {
            role: defaultRole,
            expiresAt: expiresAt.toISOString(),
            activatesAt: activatesAt.toISOString(),
            duration: 365,
            cost: 0,
            invoiceNumber: "",
            paid: true,
            eventCredit: 0,
            teeTimeCredit: 0,
            creditExempt: false,
            purchaseTransaction: null,
        };

        const { member } = this.state;
        member.roles.push(newRole);
        this.setState({ member, editing: member.roles.length - 1 });
    }

    onDoneEdit(role) {
        const { member } = this.state;
        member.roles[this.state.editing] = role;
        this.props.saveRoles();
        this.setState({ member, editing: null });
    }

    onCancelEdit() {
        const { member } = this.state;
        this.setState({ member, editing: null });
    }

    mashieAdminOnlyDetials(role) {
        if (this.props.user.has("MASHIE_ADMIN")) {
            return (
                <>
                    <p className="m-0">
                        <b>Cost:</b> &#163;{role.cost}
                    </p>
                    <p className="m-0">
                        <b>Paid:</b>{" "}
                        {role.paid ? (
                            <Icon name="check" color="green" />
                        ) : (
                            <Icon name="times" color="red" />
                        )}
                    </p>
                    {role.invoiceNumber ? (
                        <p className="m-0">
                            <b>Invoice number:</b> {role.invoiceNumber}
                        </p>
                    ) : (
                        ""
                    )}
                </>
            );
        }
    }

    listRoles() {
        return this.state.member.roles.map((role, index) => {
            if (this.types.includes(role.role.key) === false) {
                return null;
            }

            return (
                <Segment vertical key={index}>
                    <div className="row">
                        <div className="col-10">
                            {this.expiredStatus(role)}
                            <h4 className="mt-2">{role.role.name}</h4>
                            <p className="m-0">
                                <b>Payment method:</b>{" "}
                                {role.purchaseTransaction === null
                                    ? "Invoice"
                                    : "Card"}
                            </p>
                            <p className="m-0">
                                <b>Activates on:</b>{" "}
                                {moment
                                    .utc(role.activatesAt)
                                    .format("DD/MM/YYYY")}
                            </p>
                            <p className="m-0">
                                <b>Expires on:</b>{" "}
                                {role.expiresAt
                                    ? moment
                                          .utc(role.expiresAt)
                                          .format("DD/MM/YYYY")
                                    : "Never"}
                            </p>
                            <p className="m-0">
                                <b>Duration:</b> {role.duration} days
                            </p>
                            {this.mashieAdminOnlyDetials(role)}
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                            <Button
                                primary
                                className="theme"
                                onClick={() => this.editRole(index)}
                            >
                                Edit
                            </Button>
                            <Button
                                primary
                                onClick={() => this.deleteMembership(index)}
                                className="red"
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Segment>
            );
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    <label className="with-input">Membership number</label>
                    {this.errorsFor("membershipNumber")}
                    <Input
                        fluid
                        name="membershipNumber"
                        value={this.state.member.membershipNumber}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">
                        Membership payment type
                    </label>
                    <Input
                        fluid
                        name="membershipPaymentType"
                        value={this.state.member.membershipPaymentType ?? ""}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Online credit code</label>
                    <Input
                        fluid
                        name="onlineCreditCode"
                        value={this.state.member.onlineCreditCode ?? ""}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <h2>Current memberships</h2>
                {this.state.editing === null ? (
                    <>
                        <Segment vertical>
                            <Button
                                primary
                                className="theme"
                                onClick={this.addMembership.bind(this)}
                            >
                                Add membership
                            </Button>
                        </Segment>
                        {this.listRoles()}
                    </>
                ) : (
                    <MembershipTypeSelection
                        roles={this.props.roles}
                        done={this.onDoneEdit.bind(this)}
                        cancel={this.onCancelEdit.bind(this)}
                        role={this.state.member.roles[this.state.editing]}
                    />
                )}
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.props.save}
                        disabled={this.props.saving}
                        loading={this.props.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Memberships);
