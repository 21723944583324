import axios from "axios";
import logoPlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class Offer {
    constructor(properties) {
        this.isPartner = false;
        this.excludedEntities = [];
        this.categories = [];
        Object.assign(this, properties);
    }

    static getById(id) {
        return axios.get(config.api_url + "offers/" + id);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "offers", {
            params,
        });
        return {
            offers: data.data.map((entity) => new Offer(entity)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "offers/list", {
            params,
        });
        return data.data.map((offer) => new Offer(offer));
    }

    update() {
        return axios.put(config.api_url + "offers/" + this._id, {
            name: this.name,
            description: this.description.replace(/<p><br ?\/?><\/p>/g, ""),
            clubDetail: this.clubDetail.replace(/<p><br ?\/?><\/p>/g, ""),
            linksDetail: this.linksDetail.replace(/<p><br ?\/?><\/p>/g, ""),
            countryDetail: this.countryDetail,
            categories: this.categories,
            actionType: this.actionType,
            actionContent: this.actionContent,
            isPartner: this.isPartner,
            isFeatured: this.isFeatured,
            excludedEntities: this.excludedEntities,
        });
    }

    updateLogo(logo) {
        return axios.put(config.api_url + "offers/" + this._id + "/logo", {
            logo,
        });
    }

    store() {
        return axios.post(config.api_url + "offers", {
            name: this.name,
            description: this.description.replace(/<p><br ?\/?><\/p>/g, ""),
            clubDetail: this.clubDetail.replace(/<p><br ?\/?><\/p>/g, ""),
            linksDetail: this.linksDetail.replace(/<p><br ?\/?><\/p>/g, ""),
            countryDetail: this.countryDetail,
            categories: this.categories,
            actionType: this.actionType,
            actionContent: this.actionContent,
            isPartner: this.isPartner,
            excludedEntities: this.excludedEntities,
        });
    }

    delete() {
        return axios.delete(config.api_url + "offers/" + this._id);
    }

    get logoUrl() {
        if (typeof this.logo !== "undefined") {
            return config.content_url + "logos/" + this.logo;
        }

        return logoPlaceholder;
    }
}

export default Offer;
