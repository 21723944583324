import React, { Component } from "react";
import { Input, Segment, Select, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import EntitySearchSelect from "../EntitySearchSelect";

class Details extends Component {
    constructor(props) {
        super(props);

        this.roles = [
            {
                key: 0,
                value: "MASHIE_COUNTRY_MEMBERSHIP",
                text: "Country members",
            },
            { key: 1, value: "MASHIE_CLUB_MEMBERSHIP", text: "Club members" },
            { key: 2, value: "MASHIE_TRIAL_MEMBERSHIP", text: "Trial members" },
            {
                key: 3,
                value: "MASHIE_FOUNDER_MEMBERSHIP",
                text: "Founder members",
            },
            { key: 4, value: "MASHIE_LINKS_MEMBERSHIP", text: "Links members" },
        ];
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <>
                        <span className="has-error">
                            {" "}
                            {this.props.validation[field][0]}{" "}
                        </span>
                        <br />
                    </>
                );
            }
        }
    }

    gatherInput(event) {
        this.props.group[event.target.name] = event.target.value;
        this.props.update(this.props.group);
    }

    onChangeRoleBlock(_, { value }) {
        this.props.group.roleBlockList = value;
        this.props.update(this.props.group);
    }

    onChangeEntityBlock(value) {
        this.props.group.entityBlockList = value;
        this.props.update(this.props.group);
    }

    rolesDropdownOptions() {
        return this.props.roles.map((role, index) => {
            return { key: index, text: role.name, value: role._id };
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">Name</label>
                    <Input
                        fluid
                        name="name"
                        value={this.props.group.name}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Membership Type Block List</h3>
                    <p>
                        Which membership types should not be able to see this
                        group of clubs in the app. Leave blank to show this
                        groups' clubs to all membership types.
                    </p>
                    <Select
                        fluid
                        multiple
                        value={this.props.group.roleBlockList}
                        options={this.rolesDropdownOptions()}
                        onChange={this.onChangeRoleBlock.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("excludedEntities")}
                    <label className="with-input">Entity Block List</label>
                    <p>
                        Which entities' members should not be able to see this
                        group of clubs in the app. Leave blank to show this
                        group's clubs to all entities.
                    </p>
                    <EntitySearchSelect
                        fluid
                        multiple
                        value={this.props.group.entityBlockList}
                        onChange={(value) => this.onChangeEntityBlock(value)}
                    />
                </Segment>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            <Button
                                size="large"
                                floated="right"
                                className="theme"
                                primary
                                onClick={this.props.save}
                                disabled={this.props.saving}
                                loading={this.props.saving}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Details);
