import React, { Component } from "react";
import { Input, Select, Segment, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import NumberInput from "../NumberInput";
import AppVariable from "../../../models/AppVariable";

class MembershipTypeSelection extends Component {
    constructor(props) {
        super(props);

        this.types = [
            "MASHIE_COUNTRY_MEMBERSHIP",
            "MASHIE_CLUB_MEMBERSHIP",
            "MASHIE_TRIAL_MEMBERSHIP",
            "MASHIE_FOUNDER_MEMBERSHIP",
            "MASHIE_LINKS_MEMBERSHIP",
        ];

        this.durations = [
            {
                key: 0,
                value: 30,
                text: "One month",
            },
            {
                key: 1,
                value: 90,
                text: "Three months",
            },
            {
                key: 2,
                value: 180,
                text: "Six months",
            },
            {
                key: 3,
                value: 365,
                text: "One year",
            },
        ];

        this.months = [
            { key: 0, value: 0, text: "January" },
            { key: 1, value: 1, text: "February" },
            { key: 2, value: 2, text: "March" },
            { key: 3, value: 3, text: "April" },
            { key: 4, value: 4, text: "May" },
            { key: 5, value: 5, text: "June" },
            { key: 6, value: 6, text: "July" },
            { key: 7, value: 7, text: "August" },
            { key: 8, value: 8, text: "September" },
            { key: 9, value: 9, text: "October" },
            { key: 10, value: 10, text: "November" },
            { key: 11, value: 11, text: "December" },
        ];

        this.years = [
            { key: 0, value: 2015, text: "2015" },
            { key: 1, value: 2016, text: "2016" },
            { key: 2, value: 2017, text: "2017" },
            { key: 3, value: 2018, text: "2018" },
            { key: 4, value: 2019, text: "2019" },
            { key: 5, value: 2020, text: "2020" },
            { key: 6, value: 2021, text: "2021" },
            { key: 7, value: 2022, text: "2022" },
            { key: 8, value: 2023, text: "2023" },
            { key: 9, value: 2024, text: "2024" },
            { key: 10, value: 2025, text: "2025" },
            { key: 11, value: 2026, text: "2026" },
            { key: 12, value: 2027, text: "2027" },
            { key: 13, value: 2028, text: "2028" },
            { key: 14, value: 2029, text: "2029" },
            { key: 15, value: 2030, text: "2030" },
            { key: 16, value: 2031, text: "2031" },
            { key: 17, value: 2032, text: "2032" },
            { key: 18, value: 2033, text: "2033" },
            { key: 19, value: 2034, text: "2034" },
            { key: 20, value: 2035, text: "2035" },
            { key: 21, value: 2036, text: "2036" },
            { key: 22, value: 2037, text: "2037" },
            { key: 23, value: 2038, text: "2038" },
            { key: 24, value: 2039, text: "2039" },
            { key: 25, value: 2040, text: "2040" },
        ];

        this.membershipSelect = props.roles
            .filter((role) => this.types.includes(role.key))
            .map((type, index) => ({
                key: index,
                value: type.key,
                text: type.name,
            }));

        this.state = {
            activeRole: props.role,
            membershipProducts: [],
        };
    }

    componentDidMount() {
        this.getMembershipProducts();
    }

    async getMembershipProducts() {
        const { data } = await AppVariable.getByIdOrKey("MEMBERSHIP_PRODUCTS");
        this.setState({
            membershipProducts: JSON.parse(data),
        });
    }

    mashieAdminFields() {
        if (this.props.user.has("MASHIE_ADMIN")) {
            return (
                <>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="with-input">Event credit</label>
                            <p>
                                This value must be a number. If you wish to
                                clear the value, set it to zero.
                            </p>
                            <NumberInput
                                decimal
                                icon="gbp"
                                iconPosition="left"
                                fluid
                                name="eventCredit"
                                disabled={this.isCountry() || this.isEdit()}
                                value={this.state.activeRole.eventCredit}
                                onChange={(value) =>
                                    this.onChangeInput("eventCredit", value)
                                }
                            />
                        </div>
                        <div className="col">
                            <label className="with-input">Teetime Credit</label>
                            <p>
                                This value must be a number. If you wish to
                                clear the value, set it to zero.
                            </p>
                            <NumberInput
                                decimal
                                icon="gbp"
                                iconPosition="left"
                                fluid
                                disabled={this.isCountry() || this.isEdit()}
                                name="teeTimeCredit"
                                value={this.state.activeRole.teeTimeCredit}
                                onChange={(value) =>
                                    this.onChangeInput("teeTimeCredit", value)
                                }
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        {this.state.activeRole.role.key !==
                            "MASHIE_COUNTRY_MEMBERSHIP" && (
                            <div className="col">
                                <label className="with-input">
                                    Cost for membership paid
                                </label>
                                <Checkbox
                                    toggle
                                    name="primary"
                                    label="Paid"
                                    checked={this.state.activeRole.paid}
                                    onChange={(_, { checked }) =>
                                        this.onChangePaid(checked)
                                    }
                                />
                            </div>
                        )}

                        {this.state.activeRole.role.key !==
                            "MASHIE_COUNTRY_MEMBERSHIP" &&
                            this.state.activeRole.purchaseTransaction ===
                                null && (
                                <div className="col">
                                    <label className="with-input">
                                        Credit exempt (do not add credit
                                        automatically at renewal)
                                    </label>
                                    <Checkbox
                                        toggle
                                        name="primary"
                                        label="Credit exempt"
                                        checked={
                                            this.state.activeRole.creditExempt
                                        }
                                        onChange={(_, { checked }) =>
                                            this.onChangeCreditExempt(checked)
                                        }
                                    />
                                </div>
                            )}
                        <div className="col">
                            <label className="with-input">
                                Membership cost
                            </label>
                            <NumberInput
                                decimal
                                icon="gbp"
                                iconPosition="left"
                                fluid
                                name="cost"
                                value={this.state.activeRole.cost}
                                onChange={(value) =>
                                    this.onChangeInput("cost", value)
                                }
                            />
                        </div>
                        <div className="col">
                            <label className="with-input">Invoice number</label>
                            <Input
                                icon="hashtag"
                                iconPosition="left"
                                fluid
                                name="invoiceNumber"
                                value={this.state.activeRole.invoiceNumber}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "invoiceNumber",
                                        target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                </>
            );
        }
    }

    onChangeInput(property, value) {
        const { activeRole } = this.state;
        activeRole[property] = value;
        this.setState({ activeRole });
    }

    onChangeDuration(value) {
        const { activeRole } = this.state;
        const expiresAt = moment.utc(activeRole.activatesAt);
        activeRole.duration = value;
        switch (activeRole.duration) {
            case 30:
                expiresAt.add(1, "month");
                break;
            case 90:
                expiresAt.add(3, "month");
                break;
            case 180:
                expiresAt.add(6, "month");
                break;
            case 365:
                expiresAt.add(1, "year");
                break;
        }

        expiresAt.subtract(1, "day");

        activeRole.expiresAt = expiresAt.endOf("day").toISOString();
        this.setState({ activeRole });
    }

    onChangeActivationMonth(value) {
        const { activeRole } = this.state;
        const activatesAt = moment
            .utc(activeRole.activatesAt)
            .month(value)
            .date(1)
            .startOf("day");
        const expiresAt = activatesAt.clone();

        switch (activeRole.duration) {
            case 30:
                expiresAt.add(1, "month");
                break;
            case 90:
                expiresAt.add(3, "month");
                break;
            case 180:
                expiresAt.add(6, "month");
                break;
            case 365:
                expiresAt.add(1, "year");
                break;
        }

        expiresAt.subtract(1, "day");

        activeRole.activatesAt = activatesAt.toISOString();
        activeRole.expiresAt = expiresAt.endOf("day").toISOString();
        this.setState({ activeRole });
    }

    onChangeActivationYear(value) {
        const { activeRole } = this.state;
        const activatesAt = moment
            .utc(activeRole.activatesAt)
            .year(value)
            .date(1)
            .startOf("day");
        const expiresAt = activatesAt.clone();
        switch (activeRole.duration) {
            case 30:
                expiresAt.add(1, "month");
                break;
            case 90:
                expiresAt.add(3, "month");
                break;
            case 180:
                expiresAt.add(6, "month");
                break;
            case 365:
                expiresAt.add(1, "year");
                break;
        }

        expiresAt.subtract(1, "day");
        activeRole.activatesAt = activatesAt.toISOString();
        activeRole.expiresAt = expiresAt.endOf("day").toISOString();
        this.setState({ activeRole });
    }

    onChangeExpirationYear(value) {
        const { activeRole } = this.state;
        const expiresAt = moment.utc(activeRole.expiresAt).year(value);
        activeRole.expiresAt = expiresAt.endOf("month").toISOString();
        this.setState({ activeRole });
    }

    onChangeExpirationMonth(value) {
        const { activeRole } = this.state;
        const expiresAt = moment.utc(activeRole.expiresAt).month(value);
        activeRole.expiresAt = expiresAt.endOf("month").toISOString();
        this.setState({ activeRole });
    }

    onChangeType(value) {
        const { activeRole } = this.state;
        activeRole.role = this.props.roles.find((role) => role.key === value);

        if (value === "MASHIE_COUNTRY_MEMBERSHIP") {
            activeRole.paid = true;
            activeRole.eventCredit = 0;
            activeRole.teeTimeCredit = 0;
        } else {
            // Can remove this once we get rid of the founder membership type
            let lookupKey = value;
            if (value === "MASHIE_FOUNDER_MEMBERSHIP") {
                lookupKey = "MASHIE_CLUB_MEMBERSHIP";
            }

            const product =
                this.state.membershipProducts.find(
                    ({ key }) => key === lookupKey
                ) ?? null;

            activeRole.eventCredit = product?.eventCredit ?? 0;
            activeRole.teeTimeCredit = product?.teeTimeCredit ?? 0;
        }

        this.setState({ activeRole });
    }

    onChangePaid(checked) {
        const { activeRole } = this.state;
        activeRole.paid = checked;
        this.setState({ activeRole });
    }

    onChangeCreditExempt(checked) {
        const { activeRole } = this.state;
        activeRole.creditExempt = checked;
        this.setState({ activeRole });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    onChangeNoExpiry(checked) {
        const { activeRole } = this.state;
        if (checked === true) {
            activeRole.expiresAt = null;
        } else {
            activeRole.expiresAt = moment.utc().toISOString();
        }
        this.setState({ activeRole });
    }

    noExpiryOverlay = ({ classNames, selectedDay, children, ...props }) => {
        const { activeRole } = this.state;
        return (
            <div className="pb-3" {...props}>
                <div className={classNames.overlay}>
                    {children}
                    <div className="text-center">
                        <Checkbox
                            toggle
                            name="primary"
                            label="No expiry"
                            checked={activeRole.expiresAt === null}
                            onChange={(_, { checked }) =>
                                this.onChangeNoExpiry(checked)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    showAvailableExpirationYears() {
        const { activeRole } = this.state;
        return this.years.filter((year) =>
            moment
                .utc(activeRole.expiresAt)
                .year(year.value)
                .isAfter(moment.utc(activeRole.activatesAt))
        );
    }

    showAvailableExpirationMonths() {
        const { activeRole } = this.state;
        return this.months.filter((month) =>
            moment
                .utc(activeRole.expiresAt)
                .month(month.value)
                .isAfter(moment.utc(activeRole.activatesAt))
        );
    }

    isCountry() {
        return this.state.activeRole.role.key === "MASHIE_COUNTRY_MEMBERSHIP";
    }

    isEdit() {
        return this.state.activeRole.hasOwnProperty("_id");
    }

    render() {
        return (
            <>
                <h3>Configure membership</h3>
                <div className="row">
                    <div className="col">
                        <label className="with-input">Membership type</label>
                        <Select
                            fluid
                            name="category"
                            placeholder="Select"
                            value={
                                this.state.activeRole.role.key || this.types[0]
                            }
                            disabled={this.isEdit()}
                            options={this.membershipSelect}
                            onChange={(_, { value }) =>
                                this.onChangeType(value)
                            }
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <span className="mr-3">Activates on the first of</span>
                        <Select
                            placeholder="Choose month"
                            value={moment
                                .utc(this.state.activeRole.activatesAt)
                                .get("month")}
                            options={this.months}
                            onChange={(_, { value }) =>
                                this.onChangeActivationMonth(value)
                            }
                        />
                        <Select
                            className="ml-3"
                            placeholder="Choose year"
                            value={moment
                                .utc(this.state.activeRole.activatesAt)
                                .get("year")}
                            options={this.years}
                            onChange={(_, { value }) =>
                                this.onChangeActivationYear(value)
                            }
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <span className="mr-3">Has a renewal duration of</span>{" "}
                        <Select
                            name="duration"
                            placeholder="Select"
                            value={this.state.activeRole.duration}
                            options={this.durations}
                            onChange={(_, { value }) =>
                                this.onChangeDuration(value)
                            }
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <span className="mr-3">
                            Expires on the{" "}
                            {moment
                                .utc(this.state.activeRole.expiresAt)
                                .format("Do")}{" "}
                            of
                        </span>
                        <Select
                            placeholder="Choose month"
                            value={moment
                                .utc(this.state.activeRole.expiresAt)
                                .get("month")}
                            options={this.showAvailableExpirationMonths()}
                            onChange={(_, { value }) =>
                                this.onChangeExpirationMonth(value)
                            }
                        />
                        <Select
                            className="ml-3"
                            placeholder="Choose year"
                            value={moment
                                .utc(this.state.activeRole.expiresAt)
                                .get("year")}
                            options={this.showAvailableExpirationYears()}
                            onChange={(_, { value }) =>
                                this.onChangeExpirationYear(value)
                            }
                        />
                    </div>
                </div>
                {this.mashieAdminFields()}
                {this.state.activeRole.purchaseTransaction !== null && (
                    <h4 className="mt-3">
                        This membership was purchased via card. Take care when
                        making changes.
                    </h4>
                )}
                <Segment vertical className="text-right">
                    <Button onClick={() => this.props.cancel()}>Back</Button>
                    <Button
                        primary
                        className="theme"
                        onClick={() => this.props.done(this.state.activeRole)}
                    >
                        Done
                    </Button>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(MembershipTypeSelection);
