import React, { Component } from "react";
import {
    Input,
    Segment,
    Form,
    Checkbox,
    TextArea,
    Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./create.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import AppVariable from "../../models/AppVariable";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";

class AppVariablesCreate extends Component {
    constructor(props) {
        super(props);
        const appVariable = new AppVariable({ isEnabled: false });
        this.state = {
            saving: false,
            error: null,
            appVariable: appVariable,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    onChangeName(event) {
        const appVariable = this.state.appVariable;
        appVariable[event.target.name] = event.target.value;
        this.setState({ appVariable });
    }

    onChangeKey(event) {
        const appVariable = this.state.appVariable;
        appVariable[event.target.name] = event.target.value;
        this.setState({ appVariable });
    }

    onChangeValue(event) {
        const { appVariable } = this.state;
        appVariable[event.target.name] = event.target.value;
        this.setState({ appVariable });
    }

    onChangeData(event) {
        const { appVariable } = this.state;
        appVariable[event.target.name] = event.target.value;
        this.setState({ appVariable });
    }

    onChangeToggleIsEnabled() {
        const { appVariable } = this.state;
        appVariable.isEnabled = !appVariable.isEnabled;
        this.setState({ appVariable });
    }

    store() {
        this.setState({ saving: true });
        this.state.appVariable
            .store()
            .then((response) => {
                this.notification("success", "App variable saved");
                this.props.history.push("/app-variables");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save app variable");
                this.handleValidationErrors(error);
            });
    }

    entityDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the app variables details.</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">
                        Name <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.appVariable.name || ""}
                        onChange={this.onChangeName.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Enabled</label>
                    <Checkbox
                        toggle
                        checked={this.state.appVariable.isEnabled}
                        onChange={this.onChangeToggleIsEnabled.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("key")}
                    <label className="with-input">
                        Key <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="key"
                        value={this.state.appVariable.key || ""}
                        onChange={this.onChangeKey.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("val")}
                    <label className="with-input">Value</label>
                    <Form>
                        <TextArea
                            name="val"
                            value={this.state.appVariable.val || ""}
                            onChange={this.onChangeValue.bind(this)}
                        />
                    </Form>
                </Segment>
                <Segment vertical>
                    {this.errorsFor("data")}
                    <label className="with-input">Data</label>
                    <Form>
                        <TextArea
                            name="data"
                            value={this.state.appVariable.data || ""}
                            onChange={this.onChangeData.bind(this)}
                        />
                    </Form>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container skinny">
                <div className="row skinny">
                    <div className="col">
                        <h2>Create app variable</h2>
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.entityDetailsTab()}
                            </Tab>
                        </Tabs>
                        <Button
                            size="large"
                            floated="right"
                            className="theme"
                            primary
                            onClick={this.store.bind(this)}
                            disabled={this.state.saving}
                            loading={this.state.saving}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(AppVariablesCreate);
