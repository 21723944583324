import React, { Component } from "react";
import { Input, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import NotificationActions from "../../../store/actions/notification";
import Map from "../Map";

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
        };
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    gatherLocationInput(index, value) {
        const { event } = this.props;
        event.coordinates[index] = value;
        this.props.update(event);
    }

    gatherLocation(value) {
        const { event } = this.props;
        event.coordinates = value;
        this.props.update(event);
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            {this.errorsFor("lat")}
                            <label className="with-input">Latitude</label>
                            <Input
                                fluid
                                name="1"
                                value={this.props.event.coordinates[1]}
                                onChange={({ target }) =>
                                    this.gatherLocationInput(1, target.value)
                                }
                            />
                        </Segment>
                    </div>
                    <div className="col">
                        <Segment vertical>
                            {this.errorsFor("lon")}
                            <label className="with-input">Longitude</label>
                            <Input
                                fluid
                                name="0"
                                value={this.props.event.coordinates[0]}
                                onChange={({ target }) =>
                                    this.gatherLocationInput(0, target.value)
                                }
                            />
                        </Segment>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            style={{
                                width: "100%",
                                height: 400,
                                borderRadius: 20,
                                overflow: "hidden",
                                marginTop: 20,
                            }}
                        >
                            <Map
                                style={{ width: "100%", height: 400 }}
                                location={this.props.event.coordinates}
                                pinMove={this.gatherLocation.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.props.save}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Location);
