import React, { useEffect } from "react";
import styles from "./notification.module.scss";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import NotificationActions from "../../store/actions/notification";

const Notification = ({ notification, dispatch, duration = 3000 }) => {
    useEffect(() => {
        setTimeout(() => {
            dispatch(NotificationActions.remove());
        }, duration);
    }, []);

    if (notification === null) return null;

    return (
        <div
            className={
                styles.notification + " " + styles[notification.type || "info"]
            }
        >
            <div
                onClick={() => dispatch(NotificationActions.remove())}
                className={styles.dismiss}
            >
                <Icon name="times" />
            </div>
            <div className={styles.content}>
                <p>{notification.text}</p>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Notification);
