import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import NotificationActions from "../../store/actions/notification";
import { Button, Label, Dropdown } from "semantic-ui-react";
import Event from "../../models/Event";
import moment from "moment";

class EventsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            table: {
                page: 1,
                term: "",
                sort: "date",
                order: "asc",
                limit: 25,
            },
            filters: {
                occurring: null,
            },
            totalRecords: 0,
            events: [],
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getEvents(this.state.table);
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    table() {
        const headers = [
            { key: "name", text: "Name", searchable: true },
            { key: "date", text: "Event date", searchable: true },
            { key: "category", text: "Category", searchable: true },
            { key: "players", text: "Players", searchable: true },
            { key: "maxAttendees", text: "Max Attendees", searchable: true },
            { key: "location", text: "Location", searchable: true },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.events.map((event) => {
            return {
                name: { value: event.name, search: event.name },
                date: {
                    value: moment(event.date).format("DD/MM/YYYY"),
                    search: moment(event.date).unix(),
                },
                category: {
                    value: event.category.toUpperCase(),
                    search: event.category,
                },
                players: {
                    value: this.getEventPlayersLabel(event),
                    search: event.players,
                },
                maxAttendees: {
                    value: event.maxUsers && (
                        <Label color="blue">{event.maxUsers}</Label>
                    ),
                    search: event.maxUsers,
                },
                location: {
                    value:
                        event.locationAddressLine1 +
                        ", " +
                        event.locationAddressCounty,
                    search: event.locationAddressLine1,
                },
                actions: {
                    value: (
                        <>
                            <Link to={`/events/${event._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getEvents.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Event date", key: "date" },
                    { text: "Name", key: "name" },
                    { text: "Category", key: "category" },
                    { text: "Location", key: "locationAddressLine1" },
                ]}
                pagination
            />
        );
    }

    getEventPlayersLabel(event) {
        if (event.players > 0) {
            return (
                <Label
                    color={this.getPlayersLabelColor(
                        event.players,
                        event.maxUsers
                    )}
                >
                    {event.players}
                </Label>
            );
        }
    }

    getPlayersLabelColor(players, maxUsers) {
        const percentage = this.calculatePercentage(players, maxUsers);

        if (percentage < 75) {
            return "green";
        }

        if (percentage >= 75 && percentage < 100) {
            return "orange";
        }

        return "red";
    }

    calculatePercentage(value, total) {
        return (value / total) * 100;
    }

    getEvents(params) {
        this.setState({ loading: true });
        Event.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    loading: false,
                    events: data.events,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load entities, please try again"
                );
            });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getEvents(table);
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-10">
                        <h2>Events</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/events/create">
                            <Button primary className="theme">
                                Add event
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Dropdown
                            selection
                            placeholder="Upcoming / past"
                            options={[
                                { key: 0, text: "All", value: null },
                                { key: 2, text: "Upcoming", value: "future" },
                                { key: 1, text: "Past", value: "past" },
                            ]}
                            value={this.state.filters.occurring}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("occurring", value)
                            }
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(EventsIndex);
