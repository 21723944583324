import React, { Component } from "react";
import {
    Input,
    Form,
    Checkbox,
    Button,
    Icon,
    Segment,
} from "semantic-ui-react";
import User from "../../models/User";
import CenterLoader from "../../assets/components/CenterLoader";
import { connect } from "react-redux";

import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Entity from "../../models/Entity";
import logo from "../../assets/images/mashie-shield-with-text.png";
import appStoreDownloadBadge from "../../assets/images/app-store-download-badge.png";
import googlePlayDownloadBadge from "../../assets/images/google-play-download-badge.png";
import styles from "./login.module.scss";
const config = require("../../config");

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            surname: "",
            email: "",
            phone: "",
            password: "",
            loading: true,
            saving: false,
            terms: false,
            entity: new Entity(),
            success: false,
            error: false,
        };

        this.termsLink = config.app_url + "terms-and-conditions/jun-2020";
        this.privacyLink = config.app_url + "privacy-policy/jun-2020";
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    componentDidMount() {
        this.checkEntityToken();
    }

    gatherInput(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    gatherCheckbox(event) {
        const set = {};
        set[event.target.previousSibling.name] =
            !event.target.previousSibling.checked;
        this.setState(set);
    }

    checkEntityToken() {
        const { token } = this.props.match.params;
        Entity.getByRegistrationToken(token)
            .then((response) => {
                this.setState({
                    loading: false,
                    entity: new Entity(response.data.data),
                });
            })
            .catch((error) => {
                this.setState({ error: true, loading: false });
            });
    }

    attemptRegistration() {
        this.setState({ saving: true });
        User.register(
            this.state.firstName,
            this.state.surname,
            this.state.email,
            this.state.phone,
            this.state.password,
            this.props.match.params.token,
            this.state.terms,
            this.termsLink,
            this.privacyLink
        )
            .then((response) => {
                this.setState({ saving: false, success: true });
            })
            .catch((error) => {
                this.handleValidationErrors(error);
                this.setState({ saving: false });
            });
    }

    showEntityLogo() {
        if (this.state.entity.hasLogo()) {
            return (
                <div className="row">
                    <img
                        className={styles.entityLogo}
                        alt=""
                        src={this.state.entity.logoUrl}
                    />
                </div>
            );
        }
    }

    contentOrError() {
        if (this.state.error) {
            return (
                <div className="row">
                    <div className="col">
                        <img className={styles.logo} alt="" src={logo} />
                        <h2 className="text-center">
                            Your registration link is invalid
                        </h2>
                        <p className="text-center">
                            Please check your registration link or contact your
                            provider
                        </p>
                    </div>
                </div>
            );
        }

        if (this.state.success) {
            return (
                <>
                    <div className="row">
                        <div className="col">
                            <p className="text-center">
                                Thank you for registering
                            </p>

                            <h2 className="text-center mb-3">
                                NOW DOWNLOAD THE APP HERE
                            </h2>
                            <p className="text-center">
                                Please check your emails for a welcome message
                                from us. You can download the MASHIE app from
                                the following stores:
                            </p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <a href="https://apps.apple.com/gb/app/id1515404005">
                                <h2 className="text-center">iPhone download</h2>
                                <img
                                    className={styles.logo}
                                    alt=""
                                    src={appStoreDownloadBadge}
                                />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://play.google.com/store/apps/details?id=app.mashie.golf">
                                <h2 className="text-center">
                                    Android download
                                </h2>
                                <img
                                    className={styles.logo}
                                    alt=""
                                    src={googlePlayDownloadBadge}
                                />
                            </a>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <h2 className="text-center mb-3">PLEASE REGISTER HERE</h2>
                <div className="text-center">
                    {this.errorsFor("authentication")}
                </div>
                <Form>
                    {this.errorsFor("firstName")}
                    <Form.Field>
                        <Input
                            inverted
                            size="huge"
                            icon="user"
                            name="firstName"
                            iconPosition="left"
                            placeholder="First name"
                            onChange={this.gatherInput.bind(this)}
                        />
                    </Form.Field>
                    {this.errorsFor("surname")}
                    <Form.Field>
                        <Input
                            inverted
                            size="huge"
                            icon="user"
                            name="surname"
                            iconPosition="left"
                            placeholder="Surname"
                            onChange={this.gatherInput.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        {this.errorsFor("email")}
                        <Input
                            inverted
                            icon="at"
                            size="huge"
                            type="email"
                            iconPosition="left"
                            name="email"
                            placeholder="Email"
                            onChange={this.gatherInput.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        {this.errorsFor("phone")}
                        <Input
                            inverted
                            icon="phone"
                            size="huge"
                            iconPosition="left"
                            name="phone"
                            placeholder="Telephone number"
                            onChange={this.gatherInput.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        {this.errorsFor("password")}
                        <Input
                            inverted
                            type="password"
                            size="huge"
                            icon="shield alternate"
                            name="password"
                            iconPosition="left"
                            placeholder="Password (minimum 8 characters)"
                            onChange={this.gatherInput.bind(this)}
                        />
                    </Form.Field>
                    <div className="text-center">{this.errorsFor("terms")}</div>
                    <Form.Field>
                        <div className="row">
                            <div className="col-2">
                                <Checkbox
                                    toggle
                                    name="terms"
                                    checked={this.state.terms}
                                    onChange={this.gatherCheckbox.bind(this)}
                                />
                            </div>
                            <div className="col-10">
                                <p>
                                    I agree to the MASHIE Members Club{" "}
                                    <a href={this.termsLink} target="_blank">
                                        Terms & Conditions
                                    </a>{" "}
                                    and{" "}
                                    <a href={this.privacyLink} target="_blank">
                                        Privacy Policy
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <Button
                            fluid
                            primary
                            size="huge"
                            disabled={this.state.terms === false}
                            className="theme"
                            loading={this.state.saving}
                            onClick={this.attemptRegistration.bind(this)}
                        >
                            <Icon name="check" />
                        </Button>
                    </Form.Field>
                </Form>
            </>
        );
    }

    render() {
        if (this.state.loading === true) {
            return <CenterLoader />;
        }

        return (
            <>
                <div className="container-fluid px-0 full-height">
                    <div className="row no-gutters full-height">
                        <div className="col-12 col-md-8">
                            <div className={styles.fullImage}>
                                <div
                                    className={styles.registrationLargeContent}
                                >
                                    <div className="row">
                                        <div className="col">
                                            {this.showEntityLogo()}
                                            <h2>
                                                WELCOME TO THE MASHIE MEMBERS
                                                CLUB
                                            </h2>
                                            <p>
                                                To complete your complimentary
                                                registration, simply enter your
                                                details. You will then be
                                                forwarded to a page where you
                                                can easily download the MASHIE
                                                Members App from either the App
                                                Store (iOS) or Google Play
                                                (Android).
                                            </p>
                                            <p>
                                                You are minutes away from
                                                accessing the UK’s premier
                                                virtual golf club.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div
                                className={
                                    "light-background " + styles.perfectCenter
                                }
                            >
                                <div
                                    className={"d-md-block " + styles.loginBox}
                                >
                                    <div className="col">
                                        <img
                                            className={styles.logo}
                                            alt=""
                                            src={logo}
                                        />
                                    </div>
                                    {this.contentOrError()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        validation: state.validation,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Login);
