import axios from "axios";
const config = require("../config");

class TeeTimeBooking {
    constructor(properties) {
        this.confirmedBooking = null;
        Object.assign(this, properties);
        if ("createdAt" in properties) {
            this.createdAt = new Date(properties.createdAt);
        }
        if ("date" in properties) {
            this.date = new Date(properties.date);
        }
        if ("refundedAt" in properties) {
            this.refundedAt = new Date(properties.refundedAt);
        }

        if (typeof this.confirmedBooking?.date === "string") {
            this.confirmedBooking.date = new Date(this.confirmedBooking.date);
        }
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "teetimes", {
            params,
        });

        return {
            data: data.data.map((item) => new TeeTimeBooking(item)),
            total: data.total,
        };
    }

    static async getCalendarBookings(params = {}) {
        const response = await axios.get(config.api_url + "teetimes/calendar", {
            params,
        });
        return response.data.data.map((item) => {
            item.start = new Date(item.start);
            item.end = new Date(item.end);
            return item;
        });
    }

    static async getById(id) {
        const { data } = await axios.get(config.api_url + "teetimes/" + id);
        return new TeeTimeBooking(data.data);
    }

    static async userIndex(id, params = {}) {
        const { data } = await axios.get(
            config.api_url + "users/" + id + "/teetimes",
            { params }
        );
        return {
            data: data.data.map((item) => new TeeTimeBooking(item)),
            total: data.total,
        };
    }

    async store() {
        const { data } = await axios.post(config.api_url + "teetimes", this);
        return new TeeTimeBooking(data.data);
    }

    async update() {
        const response = await axios.put(
            config.api_url + "teetimes/" + this._id,
            {
                status: this.status,
                paymentStatus: this.paymentStatus,
                paymentLink: this.paymentLink,
            }
        );

        return new TeeTimeBooking(response.data.data);
    }

    async updateConfirmedBooking() {
        const response = await axios.put(
            config.api_url + "teetimes/" + this._id + "/confirmed-booking",
            {
                course: this.confirmedBooking.course._id,
                date: this.confirmedBooking.date,
                time: this.confirmedBooking.time,
                players: this.confirmedBooking.players,
                price: this.confirmedBooking.price,
                bookingFee: this.confirmedBooking.bookingFee,
            }
        );
        return response.data.data;
    }

    claim() {
        return axios.post(config.api_url + "teetimes/" + this._id + "/claim");
    }

    watch() {
        return axios.post(config.api_url + "teetimes/" + this._id + "/watch");
    }

    delete() {
        return axios.delete(config.api_url + "teetimes/" + this._id);
    }

    static async getCustomerNotes(id) {
        const response = await axios.get(
            config.api_url + "teetimes/" + id + "/customer-notes"
        );

        return response.data.data;
    }

    static async getInternalNotes(id) {
        const response = await axios.get(
            config.api_url + "teetimes/" + id + "/internal-notes"
        );

        return response.data.data;
    }

    async storeCustomerNote(note) {
        const response = await axios.post(
            config.api_url + "teetimes/" + this._id + "/customer-notes",
            {
                note,
            }
        );
        return response.data.data;
    }

    async storeInternalNote(note) {
        const response = await axios.post(
            config.api_url + "teetimes/" + this._id + "/internal-notes",
            {
                note,
            }
        );
        return response.data.data;
    }

    markCustomerNotesRead() {
        return axios
            .post(
                config.api_url +
                    "teetimes/" +
                    this._id +
                    "/customer-notes/read",
                this
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    get hasConfirmedBooking() {
        return this.confirmedBooking !== null;
    }
}

export default TeeTimeBooking;
