import React, { Component } from "react";
import { Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import NumberInput from "../NumberInput";

class Availability extends Component {
    constructor(props) {
        super(props);
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <>
                        <span className="has-error">
                            {" "}
                            {this.props.validation[field][0]}{" "}
                        </span>
                        <br />
                    </>
                );
            }
        }
    }

    gatherInput(property, value) {
        this.props.group.availability[property] = value;
        this.props.update(this.props.group);
    }

    render() {
        return (
            <>
                <Segment vertical>
                    {this.errorsFor("availability")}
                    <label className="with-input">Monday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.monday}
                        onChange={(value) => this.gatherInput("monday", value)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Tuesday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.tuesday}
                        onChange={(value) => this.gatherInput("tuesday", value)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Wednesday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.wednesday}
                        onChange={(value) =>
                            this.gatherInput("wednesday", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Thursday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.thursday}
                        onChange={(value) =>
                            this.gatherInput("thursday", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Friday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.friday}
                        onChange={(value) => this.gatherInput("friday", value)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Saturday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.saturday}
                        onChange={(value) =>
                            this.gatherInput("saturday", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Sunday</label>
                    <NumberInput
                        fluid
                        value={this.props.group.availability.sunday}
                        onChange={(value) => this.gatherInput("sunday", value)}
                    />
                </Segment>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            <Button
                                size="large"
                                floated="right"
                                className="theme"
                                primary
                                onClick={this.props.save}
                                disabled={this.props.saving}
                                loading={this.props.saving}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Availability);
