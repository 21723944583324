import React from "react";

export default function ({ score, quantity = null }) {
    const options = ["☆☆☆☆☆", "★☆☆☆☆", "★★☆☆☆", "★★★☆☆", "★★★★☆", "★★★★★"];
    return (
        <p>
            {options[Math.round(score)]}
            <span className={"ml-2"}>
                <strong>{score?.toFixed(1)}</strong>
            </span>
            {typeof quantity === "number" && (
                <span className={"ml-1"}>({quantity})</span>
            )}
        </p>
    );
}
