import React, { useEffect, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const render = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
};

function MapInternal({ center, zoom, style, location, pinMove }) {
    const ref = useRef();

    useEffect(() => {
        const handleDragEnd = (event) => {
            const result = event.latLng.toJSON();
            pinMove([result.lng, result.lat]);
        };

        const map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });

        const markerImage = {
            url: "/mashie-pin.png",
            size: new window.google.maps.Size(23, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(11, 40),
            scaledSize: new window.google.maps.Size(23, 40),
        };

        if (
            typeof location !== "undefined" &&
            typeof location[0] !== "undefined" &&
            typeof location[1] !== "undefined"
        ) {
            const pin = new window.google.maps.LatLng(
                parseFloat(location[1]),
                parseFloat(location[0])
            );
            const marker = new window.google.maps.Marker({
                position: pin,
                map: map,
                title: "",
                icon: markerImage,
                draggable: true,
            });
            marker.addListener("dragend", handleDragEnd);
        } else {
            const pin = new window.google.maps.LatLng(0, 0);
            const marker = new window.google.maps.Marker({
                position: pin,
                map: map,
                title: "Move pin to set",
                icon: markerImage,
                draggable: true,
            });
            marker.addListener("dragend", handleDragEnd);
        }
    });

    return <div ref={ref} id="map" style={style} />;
}

function Map({ style, location, pinMove }) {
    const center = {
        lat:
            typeof location !== "undefined" &&
            typeof location[1] !== "undefined"
                ? parseFloat(location[1])
                : 0,
        lng:
            typeof location !== "undefined" &&
            typeof location[0] !== "undefined"
                ? parseFloat(location[0])
                : 0,
    };
    const zoom = 10;

    return (
        <Wrapper
            apiKey="AIzaSyAs_ascZBHN1UOpg9BvRc0jkqqlIkIPgF8"
            render={render}
        >
            <MapInternal
                center={center}
                zoom={zoom}
                style={style}
                location={location}
                pinMove={pinMove}
            />
        </Wrapper>
    );
}

export default Map;
